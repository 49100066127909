import { createSlice } from '@reduxjs/toolkit';
import commonService from '../../../services/commonServiceInstance';
import { handleApiCall } from '../../../helpers/handleApiCall';

const initialState = {
    patient: {},
    users: [],
    providerTags: null,
};

const slice = createSlice({
    name: 'dropdowns',
    initialState,
    reducers: {
        // when options need to be persisted to redux state
        setOptions(state, action) {
            const { key, options } = action.payload;
            if (!state[key]) state[key] = {};
            state[key].options = options;
        },
        setSearchInProgress(state, action) {
            state.searchInProgress = action.payload;
        },
    },
});

export const { setOptions } = slice.actions;

export const dropdowns = { reducer: slice.reducer, initialState };
export default dropdowns;

// Thunks (side effects)

export const searchClientFacilities =
    (searchTerm, clientId, facilityId, requiredPermission) => async (dispatch, getState) => {
        const result = await handleApiCall(
            async () => {
                return await commonService.searchClientFacilities({
                    searchTerm,
                    clientId,
                    facilityId,
                    requiredPermission,
                });
            },
            { dispatch },
        );

        return result?.clientsAndFacilities;
    };

export const searchClients =
    ({ searchTerm, requiredPermission, clientId }) =>
    async (dispatch, getState) => {
        if (!clientId && (searchTerm === null || searchTerm === '')) {
            return [];
        }
        const result = await handleApiCall(
            async () => {
                return await commonService.getAllClients({
                    clientId,
                    requiredPermission,
                    searchTerm,
                });
            },
            { dispatch },
        );

        if (!result?.wasSuccessful) {
            return [];
        }

        return result?.clients;
    };

export const listAllClients = ({ searchTerm }) => {
    return async (dispatch) => {
        const results = await handleApiCall(
            async () => await commonService.listClients({ searchTerm, page: 1, pageSize: 1000 }),
            { dispatch },
        );

        return results.items;
    };
};

export const searchInsurancePlans = (clientId, searchTerm) => async (dispatch) => {
    if (searchTerm === null || searchTerm === '') {
        return [];
    }

    const result = await handleApiCall(
        async () => await commonService.getInsurancePlans({ clientId, searchTerm }),
        { dispatch },
    );

    if (!result.wasSuccessful) {
        return [];
    }

    return result?.insurancePlans;
};

/*
    UserSpecialtiesUserTypesSelect thunks
*/

export const getSpecialtiesAndUserTypes = (data) => {
    return async (dispatch) => {
        const results = await handleApiCall(
            async () => await commonService.getSpecialtiesAndUserTypes(data),
            { dispatch },
        );

        return results?.items;
    };
};

/*
    PatientAsyncSelect thunks
*/

export const searchPatients =
    (phrase, patientId, onBehalfOfUserId, facilityId) => async (dispatch, getState) => {
        if (!phrase?.length && !patientId) return [];

        const result = await handleApiCall(
            async () => {
                return await commonService.findPatients(
                    {
                        patientId,
                        phrase,
                        onBehalfOfUserId,
                        facilityId,
                    },
                    true,
                );
            },
            { dispatch },
        );
        if (!result.wasSuccessful) return false;

        return result.patients.items;
    };

/*
    UserAsyncSelectList thunks
*/
export const searchUsers =
    (
        {
            searchTerm,
            patientId,
            userIds,
            specialtyOrUserType,
            excludedUserIds,
            excludeLoggedInUser = true,
            excludeProviders,
            excludeSuperUsers,
            popularUsersListType,
            patientEncounterId,
            healthcareOrganizationId,
            includeSystemUsers,
            providersOnly,
            clientId,
            sortBy,
        },
        noParallelRequests = true,
    ) =>
    async (dispatch) => {
        const emptyResult = {
            page: 1,
            pageSize: 10,
            items: [],
            totalItemCount: 0,
            totalPageCount: 0,
        };

        if (
            (searchTerm === null || searchTerm === '') &&
            (!userIds || userIds?.length < 1) &&
            !popularUsersListType
        ) {
            return emptyResult;
        }

        const result = await handleApiCall(
            async () => {
                return await commonService.searchUsers(
                    {
                        searchTerm,
                        userIds,
                        excludeLoggedInUser,
                        IncludeSpecialtyName: true,
                        IncludeOrganizationName: true,
                        includeIndicatorOfCareTeamMembershipForPatientId: patientId,
                        specialtyOrUserType,
                        excludeProviders,
                        excludeSuperUsers,
                        onlyPopular:
                            !!patientId || !!patientEncounterId ? popularUsersListType : null,
                        patientId,
                        patientEncounterId,
                        healthcareOrganizationId,
                        includeSystemUsers,
                        excludedUserIds,
                        providersOnly,
                        clientId,
                        sortBy,
                    },
                    noParallelRequests,
                );
            },
            { dispatch },
        );

        if (!result.wasSuccessful) return emptyResult;

        return result?.users;
    };

export const searchProviderUsers =
    (searchTerm, patientId, userIds, healthcareProviderIds) => async (dispatch, getState) => {
        const emptyResult = {
            page: 1,
            pageSize: 10,
            items: [],
            totalItemCount: 0,
            totalPageCount: 0,
        };

        const hasInitialIds =
            (userIds && userIds.length > 0) ||
            (healthcareProviderIds && healthcareProviderIds.length > 0);
        if ((searchTerm === null || searchTerm === '') && !hasInitialIds) {
            return emptyResult;
        }

        const result = await handleApiCall(
            async () => {
                return await commonService.searchProviderUsers({
                    searchTerm,
                    userIds,
                    healthcareProviderIds,
                    includeIndicatorOfCareTeamMembershipForPatientId: patientId,
                });
            },
            { dispatch },
        );

        return result.providers || emptyResult;
    };

/*
    ProxyableUsersDropdown thunks
*/
export const getImpersonatableUsers = () => async (dispatch) => {
    const result = await handleApiCall(
        async () => {
            return await commonService.getImpersonatableUsers({
                includeUnreadMessageCounts: true,
                excludeSelf: true,
            });
        },
        {
            dispatch,
        },
    );

    if (result.wasSuccessful) {
        return result.users;
    }
};

/**
    RecentEncountersAsyncSelect thunks
 */
export const getRecentEncounters = (patientId, patientEncounterId) => async (dispatch) => {
    const result = await handleApiCall(
        async () => {
            return await commonService.getPatientEncounters({
                patientId,
                patientEncounterId,
                count: 50,
            });
        },
        { dispatch },
    );

    if (!result.wasSuccessful) {
        return [];
    }

    return result.patientEncounters;
};

/**
 * SpecialtiesAsyncSelect thunks
 */
export const searchSpecialties =
    (searchTerm, healthcareProviderSpecialtyIds) => async (dispatch) => {
        const result = await handleApiCall(
            async () => {
                return await commonService.getSpecialties({
                    searchTerm,
                    healthcareProviderSpecialtyIds,
                });
            },
            { dispatch },
        );

        if (!result.wasSuccessful) {
            return [];
        }

        return result.specialties;
    };

/**
 * ProviderTagSelect thunks
 */
export const loadProviderTags =
    ({ searchTerm, initialTagIds }) =>
    async (dispatch, getState) => {
        const cachedTags = getState().dropdowns.providerTags;

        if (!cachedTags || searchTerm || initialTagIds?.length) {
            const { providerTags } = await handleApiCall(
                async () => {
                    return await commonService.getProviderTags({
                        searchTerm,
                        HealthcareProviderTagIds: initialTagIds,
                    });
                },
                {
                    dispatch,
                },
            );

            dispatch(setOptions({ key: 'providerTags', options: providerTags }));
            return providerTags || [];
        }

        return cachedTags;
    };

/**
 * TaskTagSelect thunks
 */
export const loadTaskTags =
    ({ tagIds, tagNames, patientEncounterId }) =>
    async (dispatch) => {
        const result = await handleApiCall(
            async () => {
                return commonService.getTags({ tagIds, tagNames, patientEncounterId });
            },
            { dispatch },
        );

        if (!result?.wasSuccessful) {
            return [];
        }

        return result?.tags;
    };

/**
 * WorkQueueAsyncSelect thunks
 */
export const getCategorizedWorkQueues = () => async (dispatch) => {
    return await handleApiCall(
        async () => {
            return await commonService.getCategorizedWorkQueues();
        },
        { dispatch },
    );
};
