import dialogRoot from './DialogRoot/redux';
import clientSelector from './ClientSelectorDialog/redux';
import addressDialog from './AddressDialog/redux';
import addFindAddressDialog from './AddFindAddressDialog/redux';
import addFindProvider from './AddFindProviderDialog/redux';
import providerInfo from './ProviderEditorInfoDialog/redux';
import providerViewInfo from './ProviderViewInfoDialog/redux';
import addFindOrganization from './AddFindOrganizationDialog/redux';
import leaveCareTeam from './LeaveCareTeamDialog/redux';
import sendPatientSms from './SendPatientSmsDialog/redux';
import notificationFiltersDialog from './NotificationFiltersDialog/redux';
import uploadChartDialog from './UploadChartDialog/redux';
import supportRequestDialog from './SupportRequestDialog/redux';

export default {
    dialogRoot,
    clientSelector,
    addressDialog,
    addFindAddressDialog,
    addFindProvider,
    providerInfo,
    providerViewInfo,
    addFindOrganization,
    leaveCareTeam,
    sendPatientSms,
    notificationFiltersDialog,
    uploadChartDialog,
    supportRequestDialog,
};
