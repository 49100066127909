import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../../helpers/handleApiCall';
import commonService from '../../../services/commonServiceInstance';

const initialState = {
    reason: '',
};
const slice = createSlice({
    name: 'leaveCareTeamDialog',
    initialState,
    reducers: {},
});

export const {} = slice.actions;
export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const leaveCareTeam = (patientId, careTeamMemberId) => async (dispatch, getState) => {
    const { reason } = getState().leaveCareTeam;
    const data = {
        careTeamMemberId,
        patientId,
        reason,
    };

    const result = await handleApiCall(
        async () =>
            careTeamMemberId
                ? await commonService.removeCareTeamMember(data)
                : await commonService.leaveCareTeam(data),
        {
            dispatch,
            successMessage: 'Care Team membership was successfully removed.',
        },
    );

    return result;
};
