import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isLoading: true,
    supportRequestToSend: {
        email: null,
        name: null,
        associatedClient: null,
        summary: null,
        errorCode: null,
        description: null,
        impactLevel: null,
    },
};

export const sendSupportRequest = createAsyncThunk(
    'supportRequestDialog/sendSupportRequest',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { email, name, associatedClient, summary, errorCode, description, impactLevel } =
            getState().supportRequestDialog.supportRequestToSend;

        const data = {
            name,
            email,
            clientId: associatedClient?.clientId,
            summary,
            errorCodes: errorCode,
            description,
            impact: impactLevel,
        };

        const result = await handleApiCall(
            async () => await commonService.sendSupportRequest(data),
            {
                dispatch,
            },
        );

        return result;
    },
);

const slice = createSlice({
    name: 'supportRequestDialog',
    initialState,
    reducers: {
        completeFormInit(state, action) {
            state.supportRequestToSend = action.payload;
            state.isLoading = false;
        },
    },
});

export const { completeFormInit } = slice.actions;

export default { reducer: slice.reducer, initialState };

// thunks

export const initializeForm = (errorCode) => async (dispatch, getState) => {
    const { supportRequestToSend } = getState().supportRequestDialog;
    const { firstName, lastName, email } = getState().auth?.user?.person;
    const { originClientId } = getState().auth?.user;

    const prefilledForm = {
        ...supportRequestToSend,
        errorCode,
        email,
        name: `${firstName} ${lastName}`,
        associatedClient: originClientId,
    };

    await dispatch(completeFormInit(prefilledForm));
};
