import React from 'react';
import { history } from 'routing/history';

// Redux
import { Provider } from 'react-redux';
import store from 'redux/store';

import AppRouter from 'routing/Router';

import {
    AppInsightsProvider,
    EnvironmentContext,
    ErrorBoundary,
    SentryInitializer,
} from '@cc/common';

function App() {
    return (
        <EnvironmentContext.Provider value={window.environment}>
            <Provider store={store}>
                <AppInsightsProvider history={history}>
                    <SentryInitializer>
                        <ErrorBoundary>
                            <AppRouter />
                        </ErrorBoundary>
                    </SentryInitializer>
                </AppInsightsProvider>
            </Provider>
        </EnvironmentContext.Provider>
    );
}

export default App;
