import { useEffect } from 'react';

/**
 * Sets the document title when mounted.
 * @param {string} title
 */
export const useTitle = (title) => {
    useEffect(() => {
        if (!title) return;
        document.title = title;
    }, [title]);
};
