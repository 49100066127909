import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';
import { triggerResetForm } from '../../Form';

const initialState = {
    searchForm: {
        firstName: '',
        lastName: '',
        npiNumber: '',
    },
    hasSearched: false,
    searchedValues: null,
    isLoadingSearchResults: false,
    searchResults: [],
    hasNpiMatch: false,
};

const slice = createSlice({
    name: 'addFindProvider',
    initialState,
    reducers: {
        setHasSearched: (state, action) => {
            state.hasSearched = action.payload;
        },
        setSearchedValues: (state, action) => {
            state.searchedValues = action.payload;
        },
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
        },
        setHasNpiMatch: (state, action) => {
            state.hasNpiMatch = action.payload;
        },
        setIsLoadingSearchResults: (state, action) => {
            state.isLoadingSearchResults = action.payload;
        },
        resetNonFormState(state, action) {
            const { searchForm, ...initialNonFormState } = initialState;
            return { ...state, ...initialNonFormState };
        },
    },
});

export const {
    setHasSearched,
    setSearchedValues,
    setSearchResults,
    setHasNpiMatch,
    setIsLoadingSearchResults,
    resetNonFormState,
} = slice.actions;

export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const reset = () => async (dispatch, getState) => {
    await dispatch(
        triggerResetForm({
            slice: 'addFindProvider',
            prefix: 'searchForm',
            initialValues: initialState.searchForm,
        }),
    );
    await dispatch(resetNonFormState());
};

export const findProviders = () => async (dispatch, getState) => {
    await dispatch(setHasSearched(true));
    await dispatch(setIsLoadingSearchResults(true));

    const { searchForm } = getState().addFindProvider;
    const { firstName, lastName, npiNumber } = searchForm;

    await dispatch(setSearchedValues({ firstName, lastName, npiNumber }));

    const result = await handleApiCall(
        async () => {
            return await commonService.searchProviders({ firstName, lastName, npiNumber });
        },
        { dispatch },
    );

    if (!result.wasSuccessful) return;

    await dispatch(setSearchResults(result.healthcareProviders));
    await dispatch(setIsLoadingSearchResults(false));
};

// End Custom
