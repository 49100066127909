import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationInternal(state, action) {
            state.notification = action.payload;
        },
    },
});

export const { setNotificationInternal } = slice.actions;
export const notifications = { reducer: slice.reducer, initialState };
export default notifications;

// Thunks (side effects)
export const setNotification = (notification) => (dispatch) => {
    let message, options;
    if (typeof notification === 'string' || notification instanceof String) message = notification;
    else ({ message, options } = notification);
    // timestamp is used to differentiate between notifications with the same
    // content but are different notifications
    notification = { message, options, timestamp: Date.now() };

    dispatch(setNotificationInternal(notification));
};
