import get from 'lodash/get';
import setWith from 'lodash/setWith';
import clone from 'lodash/clone';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

/**
 * Takes an array of values and returns the nested dot path, ignoring null/undefined.
 * Ex: ['workQueues', 'userForm', null, 'firstName'] => workQueues.userForm.firstName
 */
export function getPath(array) {
    return array.filter((x) => x !== null && x !== undefined).join('.');
}

/**
 * Uses lodash to select nested state.
 */
export function select(state, slice, prefix, key = null) {
    const path = getPath([slice, prefix, key]);
    return get(state, path);
}

/**
 * Uses lodash to set nested state value.
 */
export function setIn(state, slice, prefix, key, value) {
    const path = getPath([slice, prefix, key]);
    return setWith(clone(state), path, value, clone);
}

/**
 * Return multi select values based on an array of options
 */
export function getSelectedValues(options) {
    return Array.from(options)
        .filter((el) => el.selected)
        .map((el) => el.value);
}

/**
 * Return the next value for a checkbox
 */
export function getValueForCheckbox(currentValue, checked, valueProp) {
    // If the current value was a boolean, return a boolean
    if (typeof currentValue === 'boolean') {
        return Boolean(checked);
    }

    // If the currentValue was not a boolean we want to return an array
    let currentArrayOfValues = [];
    let isValueInArray = false;
    let index = -1;

    if (!Array.isArray(currentValue)) {
        // eslint-disable-next-line eqeqeq
        if (!valueProp || valueProp == 'true' || valueProp == 'false') {
            return Boolean(checked);
        }
    } else {
        // If the current value is already an array, use it
        currentArrayOfValues = currentValue;
        index = currentValue.indexOf(valueProp);
        isValueInArray = index >= 0;
    }

    // If the checkbox was checked and the value is not already present in the aray we want to add the new value to the array of values
    if (checked && valueProp && !isValueInArray) {
        return currentArrayOfValues.concat(valueProp);
    }

    // If the checkbox was unchecked and the value is not in the array, simply return the already existing array of values
    if (!isValueInArray) {
        return currentArrayOfValues;
    }

    // If the checkbox was unchecked and the value is in the array, remove the value and return the array
    return currentArrayOfValues.slice(0, index).concat(currentArrayOfValues.slice(index + 1));
}

/**
 * Omits the key and if a nested key, recuses up the path and removes parents if empty.
 */
export function omitAndRemoveEmpty(obj, key) {
    // remove the initial key
    let newObj = omit(obj, [key]);

    // if not a nested key, return
    if (key.indexOf('.') === -1) return newObj;

    // disregard the last item. If path = "data.patient.firstName", levels will be ["data", "patient"].
    const levels = key.split('.').slice(0, -1);
    while (levels.length > 0) {
        const currentLevel = levels.join('.');
        const objAtLevel = get(newObj, currentLevel);
        if (!isEmpty(objAtLevel)) break;
        newObj = omit(newObj, currentLevel);
        levels.pop(); // remove the last item
    }
    return newObj;
}

// this is essentially lodash isNil but it will also return false for empty strings
export const isNullOrEmpty = (value) => {
    if (value === null || value === undefined) return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    return false;
};
