/*
 * Returns true if the user's browser is supported.
 */

export const checkBrowserSupport = () => {
    const ua = window.navigator.userAgent;
    // Check for IEv10 and below
    const isIE10orOlder = ua.indexOf('MSIE ') > 0;
    // Check for IEv11
    const isIE11 = ua.indexOf('Trident/') > 0;

    return !isIE10orOlder && !isIE11;
};
