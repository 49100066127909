export const BaseEnum = {
    parseDescription: function (description) {
        if (this.descriptions[description]) return this.descriptions[description];

        const entries = cleanEntries(Object.entries(this));
        const entry = entries.find((entry) => entry[0] === description);
        if (entry) return entry[1];
    },
    parseValue: function (value) {
        const entries = cleanEntries(Object.entries(this));
        const entry = entries.find((entry) => entry[1] === value);
        if (entry) {
            return entry[0];
        }
    },
    getEntries: function (value) {
        const entries = cleanEntries(Object.entries(this));
        return entries;
    },
    getDescription: function (value) {
        if (!this.descriptions) return null;
        const entry = Object.entries(this.descriptions).find((entry) => entry[1] === value);
        if (entry) {
            return entry[0];
        }
        return this.parseValue(value);
    },
    getValues: function () {
        const entries = cleanEntries(Object.entries(this));
        return entries?.map((entry) => entry[1]);
    },
};

const cleanEntries = (entries) =>
    entries.reduce((accumulator, currentValue) => {
        const ignored = [
            'classes',
            'descriptions',
            'enhancedDescriptions',
            'icons',
            'tooltips',
            'sortOrder',
        ];
        const isDefined = currentValue[1] !== null && currentValue[1] !== undefined;
        const isNotAFunction = {}.toString.call(currentValue[1]) !== '[object Function]';
        const isNotIgnored = !ignored.includes(currentValue[0]);
        if (isDefined && isNotAFunction && isNotIgnored) {
            accumulator.push(currentValue);
        }
        return accumulator;
    }, []);
