import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

/**
 * Global instance of AppInsights ReactPlugin.
 */
window.AppInsightsReactPlugin = null;

/**
 * Returns the global instance of AppInsights. Only for use when a redux thunk
 * or similar "out-of-band" code needs to log to app insights. Use the `useAppInsightsContext`
 * hook to access the instance within a component.
 */
export const getAppInsightsInstance = () => window.AppInsightsReactPlugin;

/**
 * Creates a new instance of ApplicationInsights.
 * @param {*} history - Application history object.
 * @param {*} env - App environment configuration.
 * @returns AppInsight's ReactPlugin instance.
 */
export const createAppInsights = (history, env) => {
    const { PORTAL_NAME, APPINSIGHTS } = env;
    const reactPlugin = new ReactPlugin();
    // this will be initialized by portalAuth redux slice
    reactPlugin.user = null;

    // See https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript for config documentation
    const appInsights = new ApplicationInsights({
        config: {
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history },
            },
            disableTelemetry: !APPINSIGHTS.ENABLED,
            instrumentationKey: APPINSIGHTS.INSTRUMENTATION_KEY,
        },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
        envelope.data.portal = PORTAL_NAME;

        if (reactPlugin.user) {
            envelope.data.userId = reactPlugin.user.userId;
            envelope.data.userTypeId = reactPlugin.user.userTypeId;
            envelope.data.userTypeName = reactPlugin.user.userType?.userTypeName;
            envelope.data.userName = reactPlugin.user.userName;
            envelope.data.firstName = reactPlugin.user.person?.firstName;
            envelope.data.lastName = reactPlugin.user.person?.lastName;
            envelope.data.originClientId = reactPlugin.user.originClientId;
        }
        if (window) {
            envelope.data.viewportWidth = window.innerWidth;
            envelope.data.viewportHeight = window.innerHeight;
        }
        if (window?.screen) {
            envelope.data.screenWidth = window.screen.width;
            envelope.data.screenHeight = window.screen.height;
        }
    });
    appInsights.addTelemetryInitializer((envelope) => {
        // filter out noisy ResizeObserver errors
        const errorMessage =
            envelope.baseData?.exceptions?.length && envelope.baseData?.exceptions[0]?.message;

        if (errorMessage && errorMessage?.includes('ResizeObserver')) return false;

        return true;
    });

    // Copied from https://github.com/microsoft/ApplicationInsights-JS AppInsightsErrorBoundary.tsx
    // This mimics the way AppInsight's error boundary component reports exceptions and provides
    // a simplified way for calling code to report an exception.
    reactPlugin.handleException = function (error, errorInfo) {
        return this.trackException({
            error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: errorInfo,
        });
    };

    // assign global instance
    window.AppInsightsReactPlugin = reactPlugin;

    return reactPlugin;
};
