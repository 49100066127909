import { createSlice } from '@reduxjs/toolkit';
import { commonService } from '../../../services/commonServiceInstance';
import { handleApiCall } from '../../../helpers/handleApiCall';

const initialState = {
    hierarchy: null,
};

const slice = createSlice({
    name: 'clientSelector',
    initialState,
    reducers: {
        setHierarchy: (state, action) => {
            state.hierarchy = action.payload;
        },
        setClientIds: (state, action) => {
            state.clientIds = action.payload;
        },
    },
});

export const { setHierarchy, setClientIds } = slice.actions;

export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const loadHierarchy =
    (data = {}) =>
    async (dispatch, getState) => {
        const { hierarchy } = getState().clientSelector;

        if (!!hierarchy) return;

        const result = await handleApiCall(
            async () =>
                await commonService.getFacilityHierarchies({
                    ...data,
                    includeMarkets: true,
                }),
            { dispatch },
        );

        if (result.wasSuccessful) {
            const clientIds = result.allFacilityNodes.reduce((acc, { clientId }) => {
                if (acc.indexOf(clientId) === -1) {
                    acc.push(clientId);
                }
                return acc;
            }, []);

            dispatch(setClientIds(clientIds));
            dispatch(setHierarchy(result.tree));
        }
    };

export const resetHierarchy = () => async (dispatch) => {
    await dispatch(setHierarchy(null));
};
