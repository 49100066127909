import { createSlice } from '@reduxjs/toolkit';
import { ssoService } from 'services/ssoServiceInstance';
import { handleApiCall } from '@cc/common';
import {
    setToken,
    setUser,
    setHealthcareOrganization,
    setClientOrganization,
    setGenericLoginMessage,
} from './auth';
import { setUserEnrollmentToken } from './userEnrollmentWizard';
import { loadStep1 } from './userEnrollmentWizard/1-userEnrollmentWizardAccessTerms';

const slice = createSlice({
    name: 'userEnrollment',
    initialState: {
        isLoading: false,
        userEnrollmentIsAccessible: null,
        userEnrollmentIsntAccessibleReason: null,
    },
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setUserEnrollment(state, action) {
            const { wasSuccessful, validationErrors } = action.payload;

            state.userEnrollmentIsAccessible = wasSuccessful;
            state.userEnrollmentIsntAccessibleReason = validationErrors.general;
        },
    },
});

export const { setIsLoading, setUserEnrollment } = slice.actions;
export default slice.reducer;

// Thunks (side effects)

export const loadUserEnrollment = (userEnrollmentToken) => async (dispatch) => {
    await dispatch(setIsLoading(true));

    const result = await handleApiCall(
        async () => await ssoService.loadUserEnrollmentStep1({ userEnrollmentToken }),
        { dispatch, shouldShowErrorDialog: false },
    );

    // initialize common auth data, regardless if enrollment call was successful or not
    await dispatch(initAuthData(result));

    // if there was an error, redirect to login page
    if (!result.wasSuccessful) {
        await dispatch(setGenericLoginMessage(result.validationErrors.general));
        return { wasSuccessful: false, redirectUrl: '/login' };
    }

    // if enrollment isn't needed or already completed, redirect to login page with a message
    if (result.wasSuccessful && !result.enrollmentRequired) {
        await dispatch(setGenericLoginMessage('You have successfully enrolled. Welcome back.'));
        return { wasSuccessful: true, redirectUrl: '/login?enrollmentComplete=1' };
    }

    await dispatch(setUserEnrollment(result));

    // if this step is reached then the user still requires enrollment so initialize the wizard
    await dispatch(setUserEnrollmentToken(userEnrollmentToken));
    // pre-load step 1 with data from API
    await dispatch(loadStep1(result));
    await dispatch(setIsLoading(false));

    return { wasSuccessful: true };
};

export const initAuthData = (result) => async (dispatch) => {
    if (result.user) {
        await dispatch(setUser(result.user));
    }

    if (result.healthcareOrganization) {
        await dispatch(setHealthcareOrganization(result.healthcareOrganization));
    }

    if (result.clientOrganization) {
        await dispatch(setClientOrganization(result.clientOrganization));
    }

    if (result.token) {
        await dispatch(setToken(result.token));
    }
};
