import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filtersToEdit: null,
};
const slice = createSlice({
    name: 'notificationFiltersDialog',
    initialState,
    reducers: {
        setFiltersToEdit(state, action) {
            state.filtersToEdit = action.payload;
        },
    },
});

export const { setFiltersToEdit } = slice.actions;
export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const loadFilters = (eventTypeId, filters) => async (dispatch, getState) => {
    let filtersToEdit = {
        eventTypeId,
    };

    if (filters && filters.urgencyDays) {
        const { urgencyDays } = filters;
        filtersToEdit = {
            ...filtersToEdit,
            urgencyDays60: urgencyDays && urgencyDays.includes('60'),
            urgencyDays30: urgencyDays && urgencyDays.includes('30'),
            urgencyDays7: urgencyDays && urgencyDays.includes('7'),
            urgencyDays1: urgencyDays && urgencyDays.includes('1'),
        };
    }

    dispatch(setFiltersToEdit(filtersToEdit));
};
