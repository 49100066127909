import { createSlice } from '@reduxjs/toolkit';
import { setErrorMessage } from '../../../redux/slices/errors';
import { commonService } from '../../../services/commonServiceInstance';
import { handleApiCall } from '../../../helpers/handleApiCall';

const initialState = {
    isLoading: true,
    address: {
        label: '',
        address1: '',
        address2: '',
        city: '',
        state: null,
        zip: '',
        officePhone: '',
        faxNumber: '',
        locationCode: '',
        healthcareProviderAddressInfoId: null,
        healthcareProviderId: null,
    },
};

const slice = createSlice({
    name: 'addressDialog',
    initialState,
    reducers: {
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const { setAddress, setIsLoading } = slice.actions;

export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const loadAddressLocal = (address, healthcareProviderId) => async (dispatch) => {
    await dispatch(setIsLoading(true));
    await dispatch(setAddress({ ...address, healthcareProviderId }));
    await dispatch(setIsLoading(false));
};

export const loadAddress =
    (addressId, healthcareProviderAddressInfoId, healthcareProviderId) =>
    async (dispatch, getState) => {
        await dispatch(setIsLoading(true));

        // is a new address
        if (!(addressId > 0)) {
            await dispatch(
                setAddress({
                    ...initialState.address,
                    addressId,
                    healthcareProviderAddressInfoId,
                    healthcareProviderId,
                }),
            );
            await dispatch(setIsLoading(false));
            return;
        }

        const result = await handleApiCall(
            async () =>
                await commonService.getProviderAddress({
                    addressId,
                    healthcareProviderAddressInfoId,
                }),
            {
                dispatch,
            },
        );

        if (!result?.wasSuccessful) return dispatch(setIsLoading(false));

        //destructure these props after the successful result to prevent null reference
        const {
            address,
            healthcareProviderAddressInfo: { addressLabel, faxNumber, officePhone, locationCode },
        } = result;

        await dispatch(
            setAddress(
                Object.assign({}, address, {
                    healthcareProviderId,
                    healthcareProviderAddressInfoId,
                    addressLabel,
                    faxNumber,
                    officePhone,
                    locationCode,
                }),
            ),
        );

        await dispatch(setIsLoading(false));
    };

export const saveAddress = (shouldSaveLocal) => async (dispatch, getState) => {
    try {
        const {
            address: {
                addressId,
                addressLabel,
                healthcareProviderAddressInfoId,
                healthcareProviderId,
                address1,
                address2,
                city,
                state,
                zipcode,
                officePhone,
                faxNumber,
                locationCode,
                isNew: editingNew,
            },
        } = getState().addressDialog;

        const isNew = editingNew || !addressId;
        const data = {
            addressId,
            addressLabel,
            healthcareProviderAddressInfoId,
            healthcareProviderId,
            address1,
            address2,
            city,
            state,
            zipcode,
            officePhone,
            faxNumber,
            locationCode,
        };

        if (shouldSaveLocal) {
            const date = new Date();
            const tempId = date.getTime();
            return {
                wasSuccessful: true,
                address: {
                    ...data,
                    isNew,
                    addressId: data.addressId || tempId,
                    healthcareProviderAddressInfoId: data.healthcareProviderAddressInfoId || tempId,
                },
            };
        }

        const result = await handleApiCall(
            async () => await commonService.saveProviderAddress(data),
            {
                dispatch,
                mapFormErrors: true,
                slice: 'addressDialog',
                successMessage: 'Address saved successfully.',
            },
        );

        const { wasSuccessful, validationErrors } = result;

        if (!wasSuccessful) throw new Error(Object.values(validationErrors || {}).join(', '));

        return {
            wasSuccessful,
            address: {
                isNew,
                ...result?.address,
                addressId: result?.address?.addressId,
                healthcareProviderAddressInfoId:
                    result?.healthcareProviderAddressInfo?.healthcareProviderAddressInfoId,
                addressLabel: result?.healthcareProviderAddressInfo?.addressLabel,
                faxNumber: result?.healthcareProviderAddressInfo?.faxNumber,
            },
        };
    } catch (err) {
        console.error('An error occurred saving address:', err);
        await dispatch(setIsLoading(false));
        await dispatch(setErrorMessage({ description: err.message, errorObject: err }));
        return { wasSuccessful: false, validationErrors: null };
    }
};
