import React from 'react';
import { Router, Switch } from 'react-router-dom';

// Helpers
import {
    checkBrowserSupport,
    BrowserNotSupported,
    lazyWithRetry as lazy,
    SlimLayout,
    Route,
} from '@cc/common';

// Pages
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const Error = lazy(() => import('pages/Error'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const Login = lazy(() => import('pages/Login'));
const Logout = lazy(() => import('pages/Logout'));
const TwoFactor = lazy(() => import('pages/TwoFactor'));
const TwoFactorValidate = lazy(() => import('pages/TwoFactorValidate'));
const UserEnrollment = lazy(() => import('pages/UserEnrollment'));
const TermsOfUsePage = lazy(() => import('pages/siteDocuments/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('pages/siteDocuments/PrivacyPolicyPage'));
const ReleaseNotePage = lazy(() => import('pages/siteDocuments/ReleaseNotePage'));

const LoginLayout = lazy(async () => await import('layouts/LoginLayout'));
const PlainLayout = lazy(async () => await import('layouts/PlainLayout'));

import { TokenWatcher } from 'components/TokenWatcher';
import { history } from './history';

function AppRouter() {
    const isBrowserSupported = checkBrowserSupport();
    return (
        <React.Fragment>
            <Router history={history}>
                <TokenWatcher />
                {!isBrowserSupported ? (
                    <LoginLayout>
                        <BrowserNotSupported />
                    </LoginLayout>
                ) : (
                    <Switch>
                        <Route path="/error" exact title="Error">
                            <Error />
                        </Route>
                        <Route path="/logout" exact title="Logout">
                            <LoginLayout>
                                <Logout />
                            </LoginLayout>
                        </Route>
                        <Route path="/forgot-password" exact title="Forgot Password">
                            <LoginLayout>
                                <ForgotPassword />
                            </LoginLayout>
                        </Route>
                        <Route path="/change-password" exact title="Change Password">
                            <LoginLayout>
                                <ChangePassword />
                            </LoginLayout>
                        </Route>
                        <Route path="/two-factor" exact title="Two Factor">
                            <LoginLayout>
                                <TwoFactor />
                            </LoginLayout>
                        </Route>
                        <Route path="/two-factor-validate" exact title="Two Factor">
                            <LoginLayout>
                                <TwoFactorValidate />
                            </LoginLayout>
                        </Route>
                        <Route path="/user-enrollment" exact title="Enrollment">
                            <PlainLayout>
                                <UserEnrollment />
                            </PlainLayout>
                        </Route>
                        <Route path="/PrivacyPolicy/:siteDocumentId?" title="Privacy Policy">
                            <SlimLayout className="h-full">
                                <PrivacyPolicyPage />
                            </SlimLayout>
                        </Route>
                        <Route path="/TermsOfUse/:siteDocumentId?" title="Terms Of Use">
                            <SlimLayout className="h-full">
                                <TermsOfUsePage />
                            </SlimLayout>
                        </Route>
                        <Route path="/ReleaseNote/:siteDocumentId?" title="Release Note">
                            <SlimLayout className="h-full">
                                <ReleaseNotePage />
                            </SlimLayout>
                        </Route>
                        <Route path="/" exact={false} title="Login">
                            <LoginLayout>
                                <Login />
                            </LoginLayout>
                        </Route>
                    </Switch>
                )}
            </Router>
        </React.Fragment>
    );
}
export default AppRouter;
