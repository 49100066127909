import dialogs from '../../components/dialogs/redux';
import dropdowns from '../../components/forms/Dropdowns/redux';
import providerPrintout from '../../components/ProviderPrintout/redux';
import advancedProviderSearch from '../../features/advancedProviderSearch/redux';
import messages from '../../features/messages/redux';
import messagesWidget from '../../features/messages/MessagesWidget/redux';
import insuranceCollapseTree from '../../components/collapseTree/redux';
import componentErrors from '../../components/ComponentError/redux';
import siteDocument from '../../features/siteDocuments/redux';
import chartViewer from '../../features/charts/ChartViewer/redux';

// these slices will be merged into the main portal store
export const commonSlices = {
    ...dialogs,
    dropdowns,
    providerPrintout,
    advancedProviderSearch,
    messages,
    messagesWidget,
    insuranceCollapseTree,
    componentErrors,
    siteDocument,
    chartViewer,
};
