import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './css/index.scss';

// there is logic in the index.html that uses this flag
window.reactLoaded = true;
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
