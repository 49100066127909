import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';

const initialState = {
    chartToUpload: {
        documentType: null,
        dateProvided: new Date(),
        hipaaAgreementAccepted: false,
        customDocumentType: null,
    },
    initiallySelectedEncounter: null,
    isInitializing: true,
    isUploading: false,
};

const slice = createSlice({
    name: 'uploadChartDialog',
    initialState,
    reducers: {
        setInitiallySelectedEncounter(state, action) {
            state.initiallySelectedEncounter = action.payload;
        },
        setIsInitializing(state, action) {
            state.isInitializing = action.payload;
        },
        setIsUploading(state, action) {
            state.isUploading = action.payload;
        },
        reset() {
            return { ...initialState };
        },
    },
});

export const { setInitiallySelectedEncounter, setIsInitializing, setIsUploading, reset } =
    slice.actions;
export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const initializeDialog = (patientId) => async (dispatch) => {
    await dispatch(setIsInitializing(true));
    const result = await handleApiCall(
        async () => await commonService.getPatientEncounters({ patientId }),
        { dispatch },
    );
    if (result.patientEncounters) {
        await dispatch(setInitiallySelectedEncounter(result.patientEncounters[0]));
    }
    await dispatch(setIsInitializing(false));
};

export const uploadChart = (patientId, files) => async (dispatch, getState) => {
    const { chartToUpload } = getState().uploadChartDialog;

    const { customDocumentType, dateProvided, documentType, encounter } = chartToUpload;

    const chartData = {
        PatientId: patientId,
        DocumentTypeId: documentType.documentTypeId,
        DateProvided: dateProvided,
        HIPAAReleaseOnFile: true,
        CustomDocumentType: customDocumentType,
        PatientEncounterId: encounter ? encounter.patientEncounterId : null,
        DoNotDefaultToMostRecentEncounter: true,
    };

    await dispatch(setIsUploading(true));

    let onProgressUpdate = () => {};
    const result = await handleApiCall(
        async () => await commonService.uploadDocument(chartData, files, onProgressUpdate),
        {
            dispatch,
            successMessage: 'Chart uploaded successfully',
            errorMessage: 'Error uploading chart',
        },
    );

    await dispatch(setIsUploading(false));

    return result;
};
