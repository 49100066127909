import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
    name: 'componentErrors',
    initialState,
    reducers: {
        setComponentError(state, action) {
            const { componentId, description, errorObject } = action.payload;
            state[componentId] = { description, errorObject };
        },
        clearComponentError(state, action) {
            const { componentId } = action.payload;
            if (state[componentId]) delete state[componentId];
        },
    },
});

export const { setComponentError, clearComponentError } = slice.actions;
export const general = { reducer: slice.reducer, initialState };
export default general;
