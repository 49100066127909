import set from 'lodash/set';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import has from 'lodash/has';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import unset from 'lodash/unset';
import { setErrorMessage } from '../../redux/slices/errors';
import { setNotification } from '../../redux/slices/notifications';
import { omitAndRemoveEmpty, select, setIn } from './helpers';
import { FORM_GENERAL_ERRORS_KEY } from './constants';

/**
 * State common to all forms. Should be used as initial
 * state in a redux slice. Example:
 *  initialState: {
 *     ...formState,
 *     otherState: ''
 *  }
 */
export const formState = {
    errors: null,
    touched: null,
    dirty: false,
    isValid: null,
    isValidating: false,
    isSubmitting: false,
    submitCount: 0,
    pauseSubscription: null,
    isResetting: false,
};

/**
 * Prevents components from using state keys that would
 * overwrite the form state keys.
 */
const restrictedStateKeys = Object.keys(formState);

/**
 * All form reducers. These will be combined into a wrapping
 * reducer in the formReducer function.
 */
const formReducers = {
    // ------------------------------
    // REDUCERS FOR COMMON FORM STATE

    SET_ERRORS: (state, action) => {
        const { slice, prefix, errors } = action.payload;
        return setIn(state, slice, prefix, 'errors', errors);
    },

    SET_TOUCHED: (state, action) => {
        const { slice, prefix, touched } = action.payload;
        return setIn(state, slice, prefix, 'touched', touched);
    },

    SET_DIRTY: (state, action) => {
        const { slice, prefix, dirty } = action.payload;
        return setIn(state, slice, prefix, 'dirty', dirty);
    },

    SET_ISVALID: (state, action) => {
        const { slice, prefix, isValid } = action.payload;
        return setIn(state, slice, prefix, 'isValid', isValid);
    },

    SET_ISVALIDATING: (state, action) => {
        const { slice, prefix, isValidating } = action.payload;
        return setIn(state, slice, prefix, 'isValidating', isValidating);
    },

    SET_ISSUBMITTING: (state, action) => {
        const { slice, prefix, isSubmitting } = action.payload;
        return setIn(state, slice, prefix, 'isSubmitting', isSubmitting);
    },

    INCREMENT_SUBMITCOUNT: (state, action) => {
        const { slice, prefix } = action.payload;
        const submitCount = (state.submitCount === undefined ? 0 : state.submitCount) + 1;
        return setIn(state, slice, prefix, 'submitCount', submitCount);
    },

    SET_PAUSE_SUBSCRIPTION: (state, action) => {
        const { slice, prefix, pauseSubscription } = action.payload;
        return setIn(state, slice, prefix, 'pauseSubscription', pauseSubscription);
    },

    REMOVE_PAUSE_SUBSCRIPTION_FIELD: (state, action) => {
        const { slice, prefix, key } = action.payload;
        const { pauseSubscription } = select(state, slice, prefix);
        let newPauseSubscription = pauseSubscription.filter((k) => k !== key);
        if (newPauseSubscription.length === 0) newPauseSubscription = null;
        return setIn(state, slice, prefix, 'pauseSubscription', newPauseSubscription);
    },

    SET_ISRESETTING: (state, action) => {
        const { slice, prefix, isResetting } = action.payload;
        return setIn(state, slice, prefix, 'isResetting', isResetting);
    },

    // ------------------------
    // REDUCERS FOR FIELD STATE

    SET_FIELD_VALUE: (state, action) => {
        const { slice, prefix, key, value } = action.payload;
        if (restrictedStateKeys.includes(key))
            throw new Error(`'${key}' is a restricted state key used to track common form state.`);
        return setIn(state, slice, prefix, key, value);
    },

    SET_FIELD_VALUES: (state, action) => {
        const { slice, prefix, values, shouldIgnoreEmptyValues } = action.payload;
        const invalidKeys = Object.keys(values).filter((x) => restrictedStateKeys.includes(x));
        if (invalidKeys.length > 0)
            throw new Error(
                `'${invalidKeys.join(
                    ', ',
                )}' are restricted state keys and used to track common form state.`,
            );
        const prevValues = select(state, slice, prefix);
        const newValues = mergeWith({}, prevValues, values, (prevVal, newVal) => {
            //allows empty arrays and objects to overwrite previous values
            if (shouldIgnoreEmptyValues && isNil(newVal)) return prevVal;

            return newVal;
        });
        return setIn(state, slice, prefix, null, newValues);
    },

    RESET_FORM: (state, action) => {
        const { slice, prefix, initialValues } = action.payload;
        return setIn(state, slice, prefix, null, initialValues);
    },
};

/**
 * Returns an object of the shape:
 * {
 *     ACTION_NAME: 'ACTION_NAME'
 * }
 */
export const formActionTypes = Object.keys(formReducers).reduce((obj, key) => {
    obj[key] = key;
    return obj;
}, {});

/**
 * Reducer that should be attached to the master redux store as a generic reducer.
 */
export function formReducer(state, action) {
    const reducer = formReducers[action.type];
    if (!reducer) throw new Error(`Invalid form action type "${action.type}"`);

    return reducer(state, action);
}

/**
 * Test to determine if action type is a form action type.
 */
export function isFormActionType(actionType) {
    return Object.keys(formReducers).includes(actionType);
}

/**
 * -----------
 * Form thunks
 *
 */

export const triggerSetFieldValues =
    ({ slice, prefix, values, validationSchema, shouldValidate, shouldIgnoreEmptyValues }) =>
    async (dispatch, getState) => {
        await dispatch({
            type: formActionTypes.SET_FIELD_VALUES,
            payload: { slice, prefix, values, shouldIgnoreEmptyValues },
        });

        const { isValid, isDirty, touched, errors } = select(getState(), slice, prefix);

        // set form to dirty if applicable
        if (!isDirty) {
            await dispatch({
                type: formActionTypes.SET_DIRTY,
                payload: { slice, prefix, dirty: true },
            });
        }

        // modify touched object if needed
        const touchedValues = createTouched(values, true);
        const newTouched = merge({}, touched, touchedValues);
        if (!isEqual(touched, newTouched)) {
            await dispatch({
                type: formActionTypes.SET_TOUCHED,
                payload: { slice, prefix, touched: newTouched },
            });
        }

        // run field level validaton
        if (shouldValidate) {
            await dispatch(
                triggerValidationForAllFields({
                    slice,
                    prefix,
                    validationSchema,
                    shouldIgnoreEmptyValues,
                }),
            );
        }
    };

export const triggerValidationForAllFields =
    ({ slice, prefix, validationSchema }) =>
    async (dispatch, getState) => {
        const { isValid, errors } = select(getState(), slice, prefix);
        const formValues = { ...values(getState(), slice, prefix) };
        const paths = getPaths(formValues);
        const newErrors = { ...(errors || {}) };
        let errorsNeedsUpdated = false;

        paths.forEach((path) => {
            const errorEx = runValidationForField(validationSchema, path, formValues, { getState });
            if (errorEx) {
                const { message: errorMsg, path: errorPath } = errorEx;
                set(newErrors, errorPath, errorMsg);
                errorsNeedsUpdated = true;
            } else if (has(newErrors, path)) {
                unset(newErrors, path);
                errorsNeedsUpdated = true;
            }
        });
        errorsNeedsUpdated = errorsNeedsUpdated && !isEqual(errors, newErrors);

        if (errorsNeedsUpdated) {
            await dispatch({
                type: formActionTypes.SET_ERRORS,
                payload: { slice, prefix, errors: newErrors },
            });

            if (Object.keys(newErrors).length > 0 && isValid)
                await dispatch({
                    type: formActionTypes.SET_ISVALID,
                    payload: { slice, prefix, isValid: false },
                });
            else if (Object.keys(newErrors).length === 0 && !isValid)
                await dispatch({
                    type: formActionTypes.SET_ISVALID,
                    payload: { slice, prefix, isValid: true },
                });
        } else if (Object.keys(newErrors).length === 0 && !isValid) {
            // if here then there are no errors but isValid needs to be set
            await dispatch({
                type: formActionTypes.SET_ISVALID,
                payload: { slice, prefix, isValid: true },
            });
        }
    };

export const triggerSetFieldValue =
    ({ slice, prefix, key, value, validationSchema, skipInspect }) =>
    async (dispatch, getState) => {
        await dispatch({
            type: formActionTypes.SET_FIELD_VALUE,
            payload: { slice, prefix, key, value },
        });

        if (skipInspect) return;

        await dispatch(
            triggerFieldWasInspected({
                slice,
                prefix,
                key,
                value,
                validationSchema,
                shouldValidate: true,
                shouldSetDirtyFlag: true,
            }),
        );
    };

export const triggerFieldWasInspected =
    ({ slice, prefix, key, value, validationSchema, shouldValidate, shouldSetDirtyFlag }) =>
    async (dispatch, getState) => {
        // check if below logic should be skipped (happens when pauseFormFieldsSubscription is called)
        const fieldSubscriptionWasPaused = await dispatch(
            clearFieldPauseSubscription({ slice, prefix, key }),
        );
        if (fieldSubscriptionWasPaused) return;

        // set form to dirty if applicable
        const isDirty = select(getState(), slice, prefix, 'dirty');
        if (!isDirty && shouldSetDirtyFlag) {
            await dispatch({
                type: formActionTypes.SET_DIRTY,
                payload: { slice, prefix, dirty: true },
            });
        }

        // modify the touched object if needed
        const isTouched = select(getState(), slice, prefix, `touched.${key}`);
        if (!isTouched) {
            // cloneDeep is needed to update fields more than 1 level deep (see CCW-11808)
            const prevTouched = cloneDeep({ ...select(getState(), slice, prefix, 'touched') });
            const newTouched = set(prevTouched, key, true);
            await dispatch({
                type: formActionTypes.SET_TOUCHED,
                payload: { slice, prefix, touched: newTouched },
            });
        }

        // run field level validation
        if (shouldValidate) {
            const values = { ...select(getState(), slice, prefix), [key]: value };
            const errorEx = runValidationForField(validationSchema, key, values, { getState });
            const { isValid, errors } = select(getState(), slice, prefix);

            if (errorEx) {
                const prevErrors = cloneDeep({ ...errors });
                const { message: errorMsg, path: errorPath } = errorEx;
                const newErrors = set(prevErrors, errorPath, errorMsg);
                await dispatch({
                    type: formActionTypes.SET_ERRORS,
                    payload: { slice, prefix, errors: newErrors },
                });
                if (isValid !== false) {
                    await dispatch({
                        type: formActionTypes.SET_ISVALID,
                        payload: { slice, prefix, isValid: false },
                    });
                }
            } else if (has(errors, key)) {
                const prevErrors = cloneDeep({ ...errors });
                const newErrors = omitAndRemoveEmpty(prevErrors, key);
                await dispatch({
                    type: formActionTypes.SET_ERRORS,
                    payload: { slice, prefix, errors: newErrors },
                });
                // if isValid is false, may be true now, needs to be reset
                if (Object.keys(newErrors).length === 0 && !isValid)
                    await dispatch({
                        type: formActionTypes.SET_ISVALID,
                        payload: { slice, prefix, isValid: true },
                    });
            } else if ((!errors || Object.keys(errors).length === 0) && !isValid) {
                // if here then there are no errors but isValid is not set to true then
                // run validation on all fields and if passes, set isValid = true
                await dispatch(triggerValidationForAllFields({ slice, prefix, validationSchema }));
            }
        }
    };

/**
 * Trigger validation for form fields that hasn't changed (useField will automatically trigger validation for
 * fields that have changed). Useful when a field value has changed and validation should be run on other fields.
 */
export const triggerFieldsWereInspected =
    ({ slice, prefix, keys, validationSchema, shouldValidate }) =>
    async (dispatch, getState) => {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const currentValue = select(getState(), slice, prefix, key);
            await dispatch(
                triggerFieldWasInspected({
                    slice,
                    prefix,
                    key,
                    value: currentValue,
                    validationSchema,
                    shouldValidate,
                }),
            );
        }
    };

export const triggerFormSubmission =
    ({
        slice,
        prefix,
        initialValues,
        validationSchema,
        onSubmit,
        onValidated,
        captureNewInitialValues,
    }) =>
    async (dispatch, getState) => {
        const formValues = { ...values(getState(), slice, prefix) };

        // PRE-SUBMIT
        // ----------

        // touch all fields
        const touched = createTouched(formValues, true);
        await dispatch({ type: formActionTypes.SET_TOUCHED, payload: { slice, prefix, touched } });

        // set isSubmitting to true
        await dispatch({
            type: formActionTypes.SET_ISSUBMITTING,
            payload: { slice, prefix, isSubmitting: true },
        });

        // increment submit count
        await dispatch({ type: formActionTypes.INCREMENT_SUBMITCOUNT, payload: { slice, prefix } });

        // VALIDATION
        // ----------

        // set isValidating to true
        await dispatch({
            type: formActionTypes.SET_ISVALIDATING,
            payload: { slice, prefix, isValidating: true },
        });

        // run all field validation (in the future should support an async validation call as well)
        const errors = runValidationSchema(validationSchema, formValues, { getState });
        if (errors && Object.keys(errors).length > 0) {
            onValidated && onValidated(false);

            const isValid = select(getState(), slice, prefix, 'isValid');
            if (isValid !== false)
                await dispatch({
                    type: formActionTypes.SET_ISVALID,
                    payload: { slice, prefix, isValid: false },
                });

            // set isValidating to false
            await dispatch({
                type: formActionTypes.SET_ISVALIDATING,
                payload: { slice, prefix, isValidating: false },
            });

            // set errors
            await dispatch({
                type: formActionTypes.SET_ERRORS,
                payload: { slice, prefix, errors },
            });

            // set isSubmitting to false
            await dispatch({
                type: formActionTypes.SET_ISSUBMITTING,
                payload: { slice, prefix, isSubmitting: false },
            });

            // abort submission
            return;
        } else {
            onValidated && onValidated(true);

            // clear existing errors if there are any
            const existingErrors = select(getState(), slice, prefix, 'errors');
            if (existingErrors && Object.keys(existingErrors).length > 0)
                await dispatch({
                    type: formActionTypes.SET_ERRORS,
                    payload: { slice, prefix, errors: null },
                });

            // if isValid is toggled off then reset
            const isValid = select(getState(), slice, prefix, 'isValid');
            if (isValid === false || isValid === null || isValid === undefined)
                await dispatch({
                    type: formActionTypes.SET_ISVALID,
                    payload: { slice, prefix, isValid: true },
                });

            // set isValidating to false
            await dispatch({
                type: formActionTypes.SET_ISVALIDATING,
                payload: { slice, prefix, isValidating: false },
            });
        }

        // SUBMISSION
        // ----------

        const actions = {
            getValues: () => {
                // note: normally this would be handled via the redux slice, but in some edge
                // cases no redux logic needs to be performed, only form values retrieved
                const latestFormValues = { ...values(getState(), slice, prefix) };
                return latestFormValues;
            },
            completeSubmission: async () =>
                await dispatch({
                    type: formActionTypes.SET_ISSUBMITTING,
                    payload: { slice, prefix, isSubmitting: false },
                }),
            resetForm: async (keepValues) => {
                // fetch latest state, as it may have changed by the time resetForm is called
                const latestFormValues = { ...values(getState(), slice, prefix) };
                // if keeping the existing values, update the initial values reference in the component
                if (keepValues) {
                    captureNewInitialValues(latestFormValues);
                }
                await dispatch(
                    triggerResetForm({
                        slice,
                        prefix,
                        initialValues: keepValues ? latestFormValues : initialValues,
                    }),
                );
            },
            completeSubmissionAndReset: async function (keepValues) {
                await this.completeSubmission();
                await this.resetForm(keepValues);
            },
        };

        // run submission handler
        try {
            await onSubmit(actions);
        } catch (error) {
            await dispatch(
                setErrorMessage({
                    description: 'An error occurred during form submission.',
                    errorObject: error,
                }),
            );
            await actions.completeSubmission();
            console.error(
                `An uncaught error occurred in form submission handler for slice "${slice}" and prefix "${prefix}"`,
                error,
            );
        }
    };

export const triggerResetForm =
    ({ slice, prefix, initialValues }) =>
    async (dispatch, getState) => {
        await dispatch({
            type: formActionTypes.SET_ISRESETTING,
            payload: { slice, prefix, isResetting: true },
        });
        if (initialValues) {
            await dispatch({
                type: formActionTypes.RESET_FORM,
                payload: { slice, prefix, initialValues },
            });
        }
        await dispatch({
            type: formActionTypes.SET_ERRORS,
            payload: { slice, prefix, errors: null },
        });
        await dispatch({
            type: formActionTypes.SET_TOUCHED,
            payload: { slice, prefix, touched: null },
        });
        await dispatch({
            type: formActionTypes.SET_DIRTY,
            payload: { slice, prefix, dirty: false },
        });
        await dispatch({
            type: formActionTypes.SET_ISVALID,
            payload: { slice, prefix, isValid: null },
        });
        await dispatch({
            type: formActionTypes.SET_ISRESETTING,
            payload: { slice, prefix, isResetting: false },
        });
    };

export const triggerFormStateChanged =
    ({ slice, prefix, shouldValidate, validationSchema }) =>
    async (dispatch) => {
        await dispatch({
            type: formActionTypes.SET_DIRTY,
            payload: { slice, prefix, dirty: true },
        });
        if (shouldValidate) {
            await dispatch(triggerValidationForAllFields({ slice, prefix, validationSchema }));
        }
    };

export const setFormGeneralError =
    ({ slice, prefix, errorMessage }) =>
    async (dispatch, getState) => {
        const { errors } = select(getState(), slice, prefix);
        const newErrors = { ...errors, [FORM_GENERAL_ERRORS_KEY]: errorMessage };
        await dispatch({
            type: formActionTypes.SET_ERRORS,
            payload: { slice, prefix, errors: newErrors },
        });
    };

export const clearFormGeneralErrors =
    ({ slice, prefix }) =>
    async (dispatch, getState) => {
        const errors = select(getState(), slice, prefix, 'errors');
        // don't clear if already cleared
        if (!errors || !errors[FORM_GENERAL_ERRORS_KEY]) return;
        const newErrors = { ...errors };
        delete newErrors[FORM_GENERAL_ERRORS_KEY];
        await dispatch({
            type: formActionTypes.SET_ERRORS,
            payload: { slice, prefix, errors: newErrors },
        });
    };

/**
 * -------
 * Helpers
 *
 */

/**
 * Returns all the relevant values for the form state.
 */
export function values(state, slice, prefix) {
    state = select(state, slice, prefix);
    return omit(state, restrictedStateKeys);
}

/**
 * Runs validation for one field in the schema.
 */
function runValidationForField(validationSchema, path, values, context) {
    const adjustedPath = path?.replaceAll('.', '.fields.'); // adjust path to supported nested fields
    if (!validationSchema || !has(validationSchema.fields, adjustedPath)) return null;
    try {
        validationSchema.validateSyncAt(path, values, { context });
        return null;
    } catch (ex) {
        return ex;
    }
}

/**
 * Runs validation for the entire schema.
 */
function runValidationSchema(validationSchema, values, context) {
    if (!validationSchema) return null;
    try {
        validationSchema.validateSync(values, { abortEarly: false, context });
        return null;
    } catch (ex) {
        const errors = {};
        if (ex.inner) {
            ex.inner.forEach((e) => {
                set(errors, e.path, e.message);
            });
        }
        return errors;
    }
}

/**
 * Creates a touched object that has the same shape
 * as the values object but all of the property values
 * are set to true or false.
 */
function createTouched(values, touchedVal) {
    const mapToBoolean = (v, boolVal) => {
        return typeof v === 'object' && v !== null
            ? Object.assign(
                  {},
                  mapValues(v, (nestedV) => mapToBoolean(nestedV, boolVal)),
              )
            : boolVal;
    };
    return mapValues(Object.assign({}, values), (v) => mapToBoolean(v, touchedVal));
}

/**
 * Enumerates all paths within an object. For ex:
 * { a: 1, b: { c: 1, d: 1 } }
 * would return
 * [ 'a', 'b.c', 'b.d' ]
 */
function getPaths(obj) {
    const paths = [];

    const findPaths = (node, parent) => {
        Object.entries(node).forEach(([key, value]) => {
            const currentPath = parent + key;
            paths.push(currentPath);
            if (isPlainObject(value)) {
                findPaths(value, currentPath + '.');
            }
        });
    };

    findPaths(obj, '');
    return paths;
}

/**
 * Fetches the current form values in redux. Excludes form state.
 */
export const getCurrentFormValues =
    ({ slice, prefix }) =>
    async (dispatch, getState) => {
        return values(getState(), slice, prefix);
    };

/**
 * Appends validation errors from API requests to the redux form's state.
 */
export const mapValidationErrorsToFormErrors =
    ({ slice, prefix, validationErrors, validationMapping, shouldValidateNonExistentFields }) =>
    async (dispatch, getState) => {
        if (!validationErrors) return;

        const formValues = values(getState(), slice, prefix);
        const { errors } = select(getState(), slice, prefix);

        const newErrors = errors ? { ...errors } : {};
        const unmappedErrors = [];

        Object.keys(validationErrors).forEach((key) => {
            let mappedKey = key;
            if (!!validationMapping) {
                if (typeof validationMapping === 'function') {
                    mappedKey = validationMapping(key);
                } else if (has(validationMapping, key)) {
                    mappedKey = validationMapping[key];
                } else if (!has(validationMapping, key)) {
                    unmappedErrors.push(validationErrors[key]);
                }
            }
            const isIndexKey = /.*?\[(\d+)\].*?/.test(mappedKey);
            if (shouldValidateNonExistentFields || has(formValues, mappedKey) || isIndexKey) {
                set(newErrors, mappedKey, validationErrors[key]);
            } else {
                unmappedErrors.push(validationErrors[key]);
            }
        });

        if (unmappedErrors.length > 0) {
            const errorMessage =
                unmappedErrors.length > 1
                    ? `The following errors occurred: ${unmappedErrors.join(', ')}`
                    : unmappedErrors[0];
            dispatch(
                setNotification({
                    message: errorMessage,
                    options: {
                        appearance: 'error',
                        autoDismiss: false,
                    },
                }),
            );
        }

        await dispatch({
            type: formActionTypes.SET_ERRORS,
            payload: { slice, prefix, errors: newErrors },
        });
    };

/**
 * For use in redux slices when form field values need to be changed but none of the
 * field inspection logic should run. Typically triggerFieldWasInspected will run after
 * a field value is changed via redux, however the below will cause that method to exit
 * early.
 */
export const pauseFormFieldsSubscription =
    ({ slice, prefix, fields }) =>
    async (dispatch, getState) => {
        await dispatch({
            type: formActionTypes.SET_PAUSE_SUBSCRIPTION,
            payload: { slice, prefix, pauseSubscription: fields },
        });
    };

/**
 * Checks if the field subscription is paused and if so, clears it.
 * Returns true if the field subscription was paused, otherwise false.
 */
const clearFieldPauseSubscription =
    ({ slice, prefix, key }) =>
    async (dispatch, getState) => {
        const { pauseSubscription } = select(getState(), slice, prefix);
        if (!pauseSubscription || !pauseSubscription.includes(key)) return false;
        await dispatch({
            type: formActionTypes.REMOVE_PAUSE_SUBSCRIPTION_FIELD,
            payload: { slice, prefix, key },
        });
        return true;
    };

/**
 * ------------------
 * Yup custom methods
 *
 */

/**
 * Provides easier syntax for adding a custom validator
 * function. Can be used like:
 * Yup.addMethod(Yup.mixed, 'custom', customValidator);
 * Yup.string().custom('my error message', ({ value, allValues }) => { // do stuff });
 */
export function customYupValidator(message, validateFn) {
    return this.test('custom-validator', message, function (value) {
        const { path, createError, parent: allValues } = this;
        return validateFn({ value, path, createError, allValues });
    });
}
