/**
 * Global registry of dialogs.
 */
export const DialogRegistry = {
    // dictionary of dialogs. Key is dialog type, value is object
    // of shape { component, options }
    types: {},

    /**
     * Add dialog to registry.
     */
    add(type, metadata) {
        if (!type) throw new Error('Dialog type is required.');
        this.types[type] = metadata;
    },

    /**
     * Add all dialogs to the registry.
     * @param {Object[]} dialogTypes
     * @param {string} dialogTypes[].type - type of the dialog
     * @param {string} dialogTypes[].component - React component
     * @param {Object} dialogTypes[].options - options for dialog behavior
     */
    addAll(dialogTypes) {
        dialogTypes.forEach(({ type, component, options }) => {
            if (!component)
                throw new Error(
                    `Dialog component for type ${type} is was not found. Check for compilation errors in the dialog component.`,
                );
            this.add(type, { component, options });
        });
    },

    /**
     * Retrieves metadata for a given type.
     * @param {*} type - from DialogTypes.js
     * @returns - metadata object
     */
    get(type) {
        const metadata = this.types[type];
        if (!metadata) throw new Error(`Dialog type "${type}" was not found in the registy.`);
        return metadata;
    },

    /**
     * Retrieves all registered dialog types.
     * @returns - array of dialog types and metadata.
     */
    getAll() {
        return Object.entries(this.types).map(([type, metadata]) => {
            return { type, ...metadata };
        });
    },
};
