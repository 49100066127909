import React, { useEffect } from 'react';
import { init, setUser, setTag, withScope, captureException, Replay } from '@sentry/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import merge from 'lodash/merge';
import { useEnvironment } from '../../hooks/useEnvironment';
import { ErrorReportingContext } from '../../contexts/ErrorReportingContext';
import { useSelector } from 'react-redux';

const IgnoredErrorTypes = [
    // don't report unauthorized errors as they are a part of the regular auth lifecycle
    'UnauthorizedError',
    'ResizeObserver',
    'Object Not Found Matching Id',
    'AbortError',
];

const isIgnoredError = (e, shouldReportServerErrors) => {
    if (!e) return false;

    if (IgnoredErrorTypes.some((errorType) => e.name?.includes(errorType))) return true;

    // Only report server errors if they are timeout errors OR if the env variable to always report server errors is set.
    // Otherwise server errors are already reported in the API project
    if (e.name === 'ServerError')
        return e.isTimeoutError || shouldReportServerErrors ? false : true;

    return false;
};

export const SentryInitializer = ({ children }) => {
    const { SENTRY, ENVIRONMENT, PORTAL_NAME } = useEnvironment();
    const frontendVersion = process.env.REACT_APP_VERSION;
    const appInsightsContext = useAppInsightsContext();
    const user = useSelector((state) => state.auth?.user);

    useEffect(() => {
        if (!SENTRY?.ENABLED) return;

        init(
            merge(
                {
                    dsn: SENTRY.DSN,
                    environment: ENVIRONMENT,
                    release: frontendVersion,
                    beforeSend: (event, hint) => {
                        const message = hint?.originalException || { name: event?.message };
                        if (isIgnoredError(message, !!SENTRY?.SERVER_ERROR_REPORTING_ENABLED))
                            return null;
                        return event;
                    },
                },
                getSessionReplayConfig(SENTRY, ENVIRONMENT),
            ),
        );
    }, []);

    // add user context if present
    useEffect(() => {
        if (!SENTRY?.ENABLED || !user) return;

        const { userId, userName, person } = user;
        const email = person ? person.email : null;

        setUser({
            username: userName,
            id: userId,
            email: email,
        });
        // ensures the user tag is at the top of the event
        setTag('username', userName);
    }, [user]);

    useEffect(() => {
        setTag('portal', PORTAL_NAME);
    }, []);

    const handleError = (error, errorInfoOriginal = {}) => {
        const { errorCode, ...errorInfo } = errorInfoOriginal;

        // report up the chain to appinsights
        appInsightsContext?.handleException(error, errorInfoOriginal);

        if (!SENTRY.ENABLED) return;

        withScope((scope) => {
            scope.setExtras(errorInfo);
            scope.setTag('errorCode', errorCode);
            scope.setTag('environment', ENVIRONMENT);
            captureException(error);
        });
    };

    return (
        <ErrorReportingContext.Provider value={{ handleError }}>
            {children}
        </ErrorReportingContext.Provider>
    );
};

export default SentryInitializer;

//helpers

const getSessionReplayConfig = (SENTRY, ENVIRONMENT) => {
    if (!SENTRY.REPLAY_ENABLED) return {};

    // When the site hot reloads, this cannot be called again or it will throw
    // an error. This assigns the instance to a global value so it is only created once.
    const replayInstance = (window.replayInstance =
        window.replayInstance ||
        new Replay({
            mask: ['.phi'],
            maskAllText: false,
        }));

    return {
        // The sample rate for replays that begin recording immediately and last the
        // entirety of the user's session. Is a percentage between 0 and 100%.
        replaysSessionSampleRate: ENVIRONMENT === 'Local' ? 1.0 : 0.1,

        // The sample rate for replays that are recorded when an error happens.
        replaysOnErrorSampleRate: 1.0,

        integrations: [replayInstance],
    };
};
