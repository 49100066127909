import { ApiService } from '@cc/common';

export class SsoService extends ApiService {
    constructor(history) {
        super(history);
    }

    async routeLogin(data) {
        return await this.post('/api/auth/route-login', data);
    }

    async localLogin(data) {
        return await this.post('/api/auth/local-login', data);
    }

    async externalLogin(data) {
        return await this.post('/api/auth/external/challenge', data);
    }

    async forgotPassword(data) {
        return await this.post('/api/auth/forgot-password', data);
    }

    async generatePassword(data) {
        return await this.get('/api/auth/generate-password', data);
    }

    async validateChangePasswordToken(data) {
        return await this.post('/api/auth/validate-change-password', data);
    }

    async changePassword(data) {
        return await this.post('/api/auth/change-password', data);
    }

    async getTwoFactorAuthenticationOptions(data) {
        return await this.get('/api/auth/get-two-factor-authentication-options', data);
    }

    async sendTwoFactorAuthentication(data) {
        return await this.post('/api/auth/send-two-factor-authentication', data);
    }

    async validateTwoFactorAuthentication(data) {
        return await this.post('/api/auth/validate-two-factor-authentication', data);
    }

    async validateUserEnrollmentToken(data) {
        return await this.post('/api/auth/validate-user-enrollment-token', data);
    }

    async loadUserEnrollmentStep1(data) {
        return await this.get('/api/auth/user-enrollment/load-step1', data);
    }

    async completeUserEnrollmentStep1(data) {
        return await this.post('/api/auth/user-enrollment/complete-step1', data);
    }

    async loadUserEnrollmentStep2(data) {
        return await this.get('/api/auth/user-enrollment/load-step2', data);
    }

    async completeUserEnrollmentStep2(data) {
        return await this.post('/api/auth/user-enrollment/complete-step2', data);
    }

    async checkForExistingUser(data) {
        return await this.post('/api/auth/user-enrollment/check-for-user', data);
    }

    async findOrganization(data) {
        return await this.post('/api/auth/user-enrollment/find-organization', data);
    }
}
