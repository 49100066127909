import React, { Suspense } from 'react';
import { Route as ReactRouter } from 'react-router-dom';
import { useTitle } from '../hooks/useTitle';
import { RotatingDotsLoader } from '../components/progress/RotatingDotsLoader';

export const Route = ({ title, appendToTitle, noTitle = false, ...props }) => {
    useTitle(
        title
            ? appendToTitle
                ? `CareContinuity » ${title} » ${appendToTitle}`
                : `CareContinuity » ${title}`
            : null,
    );

    if (!title && !noTitle && title !== '')
        console.error('⚠ route:', props.path, 'is missing a page title! 👎');

    return (
        <Suspense fallback={<RotatingDotsLoader />}>
            <ReactRouter {...props} />
        </Suspense>
    );
};
