import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../errors/errorBoundary/ErrorBoundary';
import { SiteContainer } from './SiteContainer';
import { LoadingBar } from '../loadingBar/LoadingBar';
import { RotatingDotsLoader } from '../progress/RotatingDotsLoader';

export const SlimLayout = ({ children }) => {
    const renderLoadingBar = () => {
        return (
            <div
                className="absolute w-full"
                style={{
                    height: '5px',
                }}
            >
                <LoadingBar isPromiseIndicator />
            </div>
        );
    };

    return (
        <SiteContainer className="h-full">
            {renderLoadingBar()}
            <ErrorBoundary>
                <Suspense fallback={<RotatingDotsLoader />}>{children}</Suspense>
            </ErrorBoundary>
        </SiteContainer>
    );
};

SlimLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
