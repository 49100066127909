import format from 'date-fns/format';
import queryString from 'query-string';

export function toKeyValuePair(key, val) {
    if (Array.isArray(val)) {
        return val.map((v) => toKeyValuePair(key, v)).join('&');
    }
    if (val instanceof Date) {
        return toKeyValuePair(key, format(val, 'MM/dd/yyyy hh:mm:ss'));
    }
    return `${key.substring(0, 1).toUpperCase()}${key.substring(1)}=${encodeURIComponent(val)}`;
}

export function toQueryString(args) {
    return Object.keys(args)
        .filter((key) => args[key] !== undefined && args[key] !== null && args[key] !== '') // filter out empty values
        .map((k) => toKeyValuePair(k, args[k])) // convert to key vaue pairs
        .join('&');
}

export function parseQueryString(args) {
    return queryString.parse(args);
}
