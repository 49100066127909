import { v4 as uuidv4 } from 'uuid';
import { trackPromise } from 'react-promise-tracker';
import { toQueryString } from '../helpers/queryStrings';
import { safeParseJson } from '../helpers/stringHelpers';
import { checkForNetworkError } from '../helpers/errorHelpers';
import { PortalEvents } from '../enums';
import eventEmitter from '../events/EventEmitter';
import ServerError from './ServerError';
import UnauthorizedError from './UnauthorizedError';
import { ValidationError } from './ValidationError';

const runningRequests = [];

// to allow for debugging
if (process.env.NODE_ENV !== 'production') {
    window.runningRequests = runningRequests;
}
export class ApiService {
    constructor(history) {
        this.history = history;
        this.events = eventEmitter;
    }

    static requestOptions = {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
    };

    setHost(host) {
        this.host = host;
    }

    setTokenResolver(tokenFunc) {
        this.tokenFunc = tokenFunc;
    }

    setHistory(history) {
        this.history = history;
    }

    buildHeaders() {
        var headers = {
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
        };
        const frontendVersion = process.env.REACT_APP_VERSION;
        headers['CC-Frontend-Version'] = frontendVersion ?? 'not available';

        if (this.tokenFunc) {
            const token = this.tokenFunc();
            headers.Authorization = `Bearer ${token}`;
        }
        return headers;
    }

    handleUnauthorizedResponse(response) {
        this.events.emit(PortalEvents.Unauthorized, response);
        throw new UnauthorizedError();
    }

    handleNetworkError(error) {
        this.events.emit(PortalEvents.NetworkError, error);
    }

    checkForServerError(response) {
        //401 invalid token or no token
        //403 token has ben previously invalidated (ex: logged out)
        if (response.status === 401 || response.status === 403) {
            this.handleUnauthorizedResponse(response);
        }
        if (response.status !== 200 || !response.ok) {
            const ex = new ServerError(response);
            if (response.status === 503 || response.status === 504) ex.isTimeoutError = true;
            throw ex;
        }
    }

    checkForAppError(result, data, onAppError) {
        if (!result) return;
        if (result.wasSuccessful === false) {
            if (onAppError && typeof onAppError === 'function') {
                onAppError(result, data);
            } else {
                if (result.validationErrors && result.validationErrors.unauthorized) {
                    this.handleUnauthorizedResponse(null);
                }

                if (result.message && result.message !== null && result.message !== undefined) {
                    throw new ValidationError(result);
                }

                if (result.validationErrors && result.validationErrors instanceof Object) {
                    Object.keys(result.validationErrors).forEach((key) => {
                        let validationMsg = result.validationErrors[key];
                        console.warn(`Server Validation Error [${key}]: ${validationMsg}`);
                    });
                }
            }
        }
    }

    getFileNameFromDispositionHeader(header) {
        let contentDispostion = header.split(';');
        const fileNameToken = `filename*=UTF-8''`;
        let fileName = 'downloaded.pdf';
        for (let thisValue of contentDispostion) {
            if (thisValue.trim().indexOf(fileNameToken) === 0) {
                fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
                break;
            }
        }
        return fileName;
    }

    // bypass the usual pipeline to issue very simple request
    async issueSlimRequest(path, method, data, overrideUrl) {
        const queryString = method === 'GET' && data ? '?' + toQueryString(data) : '';
        const url = `${this.host}${path}${queryString}`;
        const body = method === 'POST' ? data : undefined;

        try {
            const response = await fetch(url, {
                ...this.constructor.requestOptions,
                method,
                headers: this.buildHeaders(),
                body: body ? JSON.stringify(body) : undefined,
                credentials: 'include',
            });
            return { response, error: null };
        } catch (error) {
            return { response: null, error };
        }
    }

    async issueRequest(path, method, data, abortSignal, onAppError) {
        const queryString = method === 'GET' && data ? '?' + toQueryString(data) : '';
        const url = `${this.host}${path}${queryString}`;
        const body = method === 'POST' ? data : undefined;

        try {
            const response = await fetch(url, {
                signal: abortSignal || null,
                ...this.constructor.requestOptions,
                method,
                headers: this.buildHeaders(),
                body: body ? JSON.stringify(body) : undefined,
                credentials: 'include',
            });

            this.checkForServerError(response);

            const dispositionHeader = response.headers.get('content-disposition');
            if (dispositionHeader) {
                const fileName = this.getFileNameFromDispositionHeader(dispositionHeader);
                const blob = await response.blob();

                var blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = fileName;
                link.click();

                return Promise.resolve({ wasSuccessful: true, validationErrors: null });
            }

            const result = await response.json();
            this.checkForAppError(result, data, onAppError);

            return result;
        } catch (error) {
            // if request was aborted, throw error, will be handled by issueCancelableRequest
            if (error.name === 'AbortError') throw error;

            console.error(error);

            if (error.getServerStackTrace) {
                const stackTrace = await error.getServerStackTrace();
                stackTrace && console.error('Server Error Stacktrace:', stackTrace);
            }

            if (checkForNetworkError(error)) {
                if (process.env.NODE_ENV !== 'production')
                    console.error(
                        'Failure to communicate with API. Ensure that the asp.net core web API is running.',
                    );
                this.handleNetworkError(error);
                throw error; // rethrow original error
            }

            if (error instanceof ServerError) {
                // if error has JSON data, try to parse it because it may contain an error code
                if (error.json) {
                    await error.json();
                }
                throw error; // rethrow original error
            } else if (error instanceof UnauthorizedError) {
                throw error; // rethrow original error
            }

            throw new Error(ServerError.DefaultErrorMessage, { cause: error });
        }
    }

    async issueCancelableRequest(path, method, data, cancelationOptions, onAppError) {
        const { id, noParallelRequests, allowAbortError } = cancelationOptions;

        if (!id) {
            throw new Error('cancelationOptions.id should be set');
        }

        if (noParallelRequests === null || noParallelRequests === undefined) {
            throw new Error('cancelationOptions.noParallelRequests should be set');
        } else if (noParallelRequests === false) {
            console.warn(
                'issueCancelableRequest has been invoked with noParallelRequests set to false, did you mean to set it to true?',
            );
            return trackPromise(this.issueRequest(path, method, data, null, onAppError), 'API');
        }

        // initialize to empty array if key doesn't already exist
        let previousRequests = runningRequests[id];
        if (!previousRequests) runningRequests[id] = previousRequests = [];

        // loop through all previous requests and cancel them if they are still running
        previousRequests.forEach((previousRequest) => {
            if (previousRequest.canceled) return;
            console.debug(
                `New request initiated for ${id}, canceling previous request with ID: ${previousRequest.requestId}`,
            );
            previousRequest.cancel();
        });

        const abortController = new AbortController();
        const requestId = uuidv4();

        previousRequests.push({
            requestId,
            canceled: false,
            cancel() {
                this.canceled = true;
                abortController.abort();
            },
        });

        return trackPromise(
            this.issueRequest(path, method, data, abortController.signal, onAppError)
                .catch((error) => {
                    // if the request was aborted and should not throw error then return undefined
                    if (error.name === 'AbortError' && !allowAbortError) {
                        console.debug(
                            `Request was aborted for ${id} but allowAbortError was false. Returning undefined.`,
                        );
                        return undefined;
                    }

                    throw error;
                })
                .finally(() => {
                    // delete request from array
                    const index = previousRequests.findIndex((r) => r.requestId === requestId);
                    previousRequests.splice(index, 1);
                }),
            'API',
        );
    }

    async get(path, data, cancelationOptions, onAppError) {
        return cancelationOptions
            ? this.issueCancelableRequest(path, 'GET', data, cancelationOptions, onAppError)
            : trackPromise(this.issueRequest(path, 'GET', data, null, onAppError), 'API');
    }

    async post(path, data, onAppError) {
        return trackPromise(this.issueRequest(path, 'POST', data, null, onAppError), 'API');
    }

    async postMultipartForm(path, data, files, onProgress) {
        try {
            return await this.issueXMLHttpRequest(path, data, files, onProgress);
        } catch (e) {
            throw new Error(
                'There was a problem uploading your file. It may be too large (over 100MB) or corrupt. Please check your file and try again.',
            );
        }
    }

    issueXMLHttpRequest(path, data, files, onProgress) {
        const url = `${this.host}${path}`;

        return new Promise((accept, reject) => {
            let formData = new FormData();

            // add model to formData instance
            formData.append('model', JSON.stringify(data));

            // add files to formData instance
            if (files && files.length > 0) {
                if (files.length > 1)
                    files.forEach((file, i) => formData.append('file' + ++i, file, file.name));
                else formData.append('file', files[0], files[0].name);
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            let headers = this.buildHeaders();

            Object.keys(headers)
                .filter((h) => h !== 'Content-Type')
                .forEach((header) => {
                    xhr.setRequestHeader(header, headers[header]);
                });

            if (onProgress) {
                xhr.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        let percentComplete = Math.round((e.loaded / e.total) * 100);
                        onProgress(percentComplete);
                    }
                };
            }

            xhr.onload = function () {
                if (this.status === 200) {
                    const parsedResult = safeParseJson(this.response);
                    accept(parsedResult);
                } else {
                    reject(this.response);
                }
            };

            xhr.onerror = function (e) {
                reject(e);
            };

            xhr.withCredentials = true;
            xhr.send(formData);
        });
    }

    // used for mock data
    wait(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}

export default ApiService;
