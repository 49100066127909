import './RotatingDotsLoader.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const RotatingDotsLoader = ({
    className,
    compact,
    hideText,
    style,
    text = 'Loading...',
    fullHeight,
}) => {
    return (
        <div
            data-test-id="rotating-dots-loader"
            className={classNames('rotating-dots-loader', className, {
                'rotating-dots-loader--full-height': fullHeight,
                'rotating-dots-loader--compact': compact,
            })}
            style={style}
        >
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {hideText ? null : <div className="rotating-dots-loader_text">{text}</div>}
        </div>
    );
};

RotatingDotsLoader.propTypes = {
    className: PropTypes.string,
    compact: PropTypes.bool,
    fullHeight: PropTypes.bool,
    hideText: PropTypes.bool,
    style: PropTypes.any,
    text: PropTypes.string,
};
