import { useRef } from 'react';

// none is a special value used to detect an uninitialised ref
const none = {};

/**
 * Version of useRef that supports lazy initial state, mimicking the behavior
 * of useState's lazy initial state (https://reactjs.org/docs/hooks-reference.html#usestate)
 * @param {Function} init
 * @see https://thoughtspile.github.io/2021/11/30/lazy-useref/
 */
export const useLazyRef = (init) => {
    // not initialized yet
    const ref = useRef(none);
    // if it's not initialized (1st render)
    if (ref.current === none) {
        // initialize it
        ref.current = init();
    }
    // ref.current will always be whatever init() returns
    // but init() is only called once on the first render
    return ref;
};
