import { createSlice } from '@reduxjs/toolkit';
import commonService from '../../../services/commonServiceInstance';
import { handleApiCall } from '../../../helpers/handleApiCall';

const DEFAULT_PAGE_SIZE = 3;

const initialState = {
    conversations: [],
    isLoading: true,
};

const slice = createSlice({
    name: 'messagesWidget',
    initialState: { ...initialState },
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setConversations(state, action) {
            state.conversations = action.payload;
            state.isLoading = false;
        },
        setTotalItemCount(state, action) {
            state.totalItemCount = action.payload;
        },
    },
});

export const { setIsLoading, setConversations, setTotalItemCount } = slice.actions;

export default { reducer: slice.reducer, initialState: {} };

// Thunks (side effects)
export const loadPatientConversations = (patientId, componentId) => async (dispatch, getState) => {
    await dispatch(setIsLoading(true));

    const { wasSuccessful, data } = await handleApiCall(
        async () =>
            await commonService.getUserConversations({
                pageSize: DEFAULT_PAGE_SIZE,
                patientId,
            }),
        {
            dispatch,
            shouldShowErrorDialog: false,
            componentId,
        },
    );

    if (!wasSuccessful) {
        return;
    }

    dispatch(setConversations(data?.items));
    dispatch(setTotalItemCount(data?.totalItemCount));
};
