import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import './LoadingBar.scss';

/**
 * Ripped from https://codepen.io/shalimano/pen/wBmNGJ
 */

export const LoadingBar = ({ isPromiseIndicator }) => {
    const { promiseInProgress } = usePromiseTracker({
        area: 'API',
        delay: 200,
    });

    if (isPromiseIndicator && !promiseInProgress) {
        return null;
    }

    return (
        <div className="loading-bar">
            <div className="line"></div>
            <div className="subline inc"></div>
            <div className="subline dec"></div>
        </div>
    );
};
