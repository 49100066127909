import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkBase } from '../base/LinkBase';
import './ActionLink.scss';

export const ActionLink = ({ className, dataTestId, onClick, children, href, target }) => {
    return (
        <LinkBase
            className={classNames('action-link', className)}
            dataTestId={dataTestId}
            onClick={onClick}
            href={href}
            target={target}
        >
            {children}
        </LinkBase>
    );
};

ActionLink.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    href: PropTypes.string,
    target: PropTypes.string,
};
