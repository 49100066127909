import { clearComponentError, setComponentError } from '../components/ComponentError/redux';
import { setErrorMessage } from '../redux/slices/errors';
import { setNotification } from '../redux/slices/notifications';
import { mapValidationErrorsToFormErrors, setFormGeneralError } from '../components/Form/redux';
import ServerError from '../services/ServerError';
import { ValidationError } from '../services/ValidationError';

const defaultErrorResult = { wasSuccessful: false, validationErrors: null };
const defaultSuccessResult = { wasSuccessful: true, validationErrors: null };
const defaultCancelResult = { wasSuccessful: false, validationErrors: null, wasCanceled: true };

const defaultOptions = {
    shouldShowErrorDialog: true,
    shouldShowErrorToast: false,
    shouldAutoDismissNotification: true,
};

/**
 *
 * @param {Function} asyncApiFn - async api function, can be one method or multiple which are awaited on
 * @param {Object} options - options for processing the API call
 * @param {Function} options.dispatch - required - reference to the dispatch function
 * @param {string} options.successMessage - optional - if successful a toast notification will be dispatched
 * @param {string} options.errorMessage - optional - if unsuccessful, error message to prepend to the error message from API
 * @param {boolean} options.mapFormErrors - optional - if true, map validationErrors to the redux form errors state
 * @param {string} options.slice - required if mapFormErrors is true - location of redux form slice
 * @param {string} options.prefix - optional - use if mapFormErrors is true - location of redux form slice
 * @param {string} options.componentId - optional - if an error occurs, it will be associated with the componentId
 * @param {boolean} options.shouldShowErrorDialog - optional (default is true) - if true, errors will be shown in a dialog
 * @param {boolean} options.shouldShowErrorToast - optional (default is false) - if true, errors will be shown in a toast notification
 * @param {boolean} options.captureFormGeneralError - optional (default is false) - if true, general error will be put in FORM_GENERAL_ERRORS_KEY in redux,
 * @param {boolean} options.shouldAutoDismissNotification - optional (default is true) - if true, success or error message will automatically disppear after a few moments
 */
export async function handleApiCall(asyncApiFn, options) {
    if (!options) throw new Error("'options' argument is required.");
    if (!options.dispatch) throw new Error("'dispatch' must be provided in the options object.");

    options = Object.assign({}, defaultOptions, options);

    const {
        componentId,
        dispatch,
        errorMessage,
        mapFormErrors,
        prefix,
        shouldShowErrorDialog,
        shouldShowErrorToast,
        shouldValidateNonExistentFields,
        slice,
        successMessage,
        validationMapping,
    } = options;

    let result;

    if (componentId) await dispatch(clearComponentError({ componentId }));

    try {
        result = await asyncApiFn();
        if (!result) {
            console.warn('asyncApiFn did not return a valid result.');
            result = defaultSuccessResult;
        }

        if (!result.wasSuccessful) {
            if (!mapFormErrors) throw new ValidationError(result);

            if (!slice)
                throw new Error(
                    "'slice' must be provided in the options object when 'mapFormErrors' is true.",
                );
            const validationErrors = result.validationErrors;
            await dispatch(
                mapValidationErrorsToFormErrors({
                    slice,
                    prefix,
                    validationErrors,
                    validationMapping,
                    shouldValidateNonExistentFields,
                }),
            );

            // the result may have a general error message, not tied to a specific field
            if (options.captureFormGeneralError && validationErrors?.general) {
                await dispatch(
                    setFormGeneralError({
                        slice,
                        prefix,
                        errorMessage: validationErrors.general,
                    }),
                );
            }
        } else if (successMessage) {
            await dispatch(
                setNotification({
                    message: successMessage,
                    options: {
                        appearance: 'success',
                        autoDismiss: options.shouldAutoDismissNotification,
                    },
                }),
            );
        }

        return result;
    } catch (err) {
        // if the call was aborted, or the call was unauthorized (thus redirected to logout)
        // or the error is a network error (lost api / internet connection)
        // don't handle it like can error, just return the default cancel result
        if (err.name === 'AbortError' || (err.name === 'ServerError' && err?.status === 401))
            return defaultCancelResult;

        // if a ServerError with JSON data, try to parse it because it may contain an error code
        if (err instanceof ServerError && err.json) {
            await err.json();
        }

        // log to console unless a ServerError as they are already logged to console
        if (!(err instanceof ServerError)) console.error('error occurred in handleApiCall():', err);

        let fullErrorMessage = err.message;
        if (errorMessage) {
            // add period if message doesn't end in punctuation
            const addPeriod = !errorMessage.match(/[.,:;!?]$/);
            fullErrorMessage = `${errorMessage}${addPeriod ? '.' : ''} ${fullErrorMessage}`;
        }

        const isValidationError = err instanceof ValidationError;
        // if the error is a validation error, we don't want to show the error dialog
        // but instead handle with a toast
        if (shouldShowErrorDialog && !isValidationError)
            await dispatch(setErrorMessage({ description: fullErrorMessage, errorObject: err }));
        if (shouldShowErrorToast || (isValidationError && !componentId))
            await dispatch(
                setNotification({
                    message: fullErrorMessage,
                    options: {
                        appearance: 'error',
                        autoDismiss: options.shouldAutoDismissNotification,
                    },
                }),
            );
        if (componentId)
            await dispatch(
                setComponentError({ componentId, description: fullErrorMessage, errorObject: err }),
            );

        return result || defaultErrorResult;
    }
}

export const DEFAULT_PAGE_SIZE = 20;
