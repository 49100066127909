import clone from 'lodash/clone';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import { useDispatch } from 'react-redux';

export class NewUpdateableData {
    recordId;
    data;
    constructor(recordId, data) {
        this.recordId = recordId;
        this.data = data;
    }
}

const actions = {
    INSERT_UPDATEABLE_DATA: 'INSERT_UPDATEABLE_DATA',
};

export const insertUpdateableDataReducer = (state, action) => {
    switch (action.type) {
        case actions.INSERT_UPDATEABLE_DATA: {
            const { slice, prefix, key, item, position } = action.payload;
            const path = (prefix ? `${slice}.${prefix}` : slice) + '.' + key;
            let array = get(state, path);

            // get reference to array and ensure it is an array
            if (array && !Array.isArray(array))
                throw new Error(`Path for updateable item was not an array`);
            else if (!array) array = [];

            const newArray = position === 'end' ? [...array, item] : [item, ...array];
            return setWith(clone(state), path, newArray, clone);
        }
        default:
            return state;
    }
};

export const isUpdateableDataActionType = (actionType) => {
    return Object.values(actions).includes(actionType);
};

export const useInsertUpdateableData = () => {
    const dispatch = useDispatch();

    return async ({ slice, prefix, key, recordId, data, position }) => {
        const item = new NewUpdateableData(recordId, data);
        return await dispatch({
            type: actions.INSERT_UPDATEABLE_DATA,
            payload: { slice, prefix, key, item, position },
        });
    };
};
