import React from 'react';
import PropTypes from 'prop-types';
import { ErrorDisplay } from './ErrorDisplay';
import { ErrorReportingContext } from '../../../contexts/ErrorReportingContext';
import { getReduxStoreKey } from '../../../helpers/localStorage';
import { generateNewErrorCode } from '../../../helpers/errorHelpers';

/**
 * Catches any Javascript errors anywhere in child components and displays
 * fallback UI. Note: fallback UI is only shown in production build.
 * See: https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorCode: null,
        };
    }

    static getDerivedStateFromError(error) {
        const errorCode = generateNewErrorCode();
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error, errorCode };
    }

    static contextType = ErrorReportingContext; // allows access to this.context

    get errorCode() {
        return this.state.errorCode ? (this.props.userId || '') + this.state.errorCode : '';
    }

    // No side-effects are allowed in this method
    componentDidCatch(error, errorInfo) {
        const extraErrorInfo = { ...errorInfo, errorCode: this.errorCode };
        console.error('An error occurred:', error, extraErrorInfo);

        this.props.reportException && this.props.reportException(error, extraErrorInfo); // log to error reporting service
        this.props.onError && this.props.onError(error, extraErrorInfo); // invoke handler if present

        // invoke context if present
        const errorReportingContext = this.context;
        if (errorReportingContext && errorReportingContext.handleError)
            errorReportingContext.handleError(error, extraErrorInfo);
    }

    getFallbackComponent() {
        return (
            this.props.FallbackComponent || (
                <ErrorDisplay
                    error={this.state.error}
                    errorCode={this.errorCode}
                    showDetails={this.props.showDetails}
                />
            )
        );
    }

    render() {
        if (this.state.hasError) {
            const localStorageKey = getReduxStoreKey();
            localStorage.removeItem(localStorageKey); // clear persistedState in case that caused the error
            return this.getFallbackComponent();
        }
        return this.props.children;
    }
}

ErrorBoundary.defaultProps = {
    showDetails: false,
};

ErrorBoundary.propTypes = {
    onError: PropTypes.func,
    FallbackComponent: PropTypes.oneOf([PropTypes.element, PropTypes.elementType, PropTypes.node]),
    showDetails: PropTypes.bool,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
