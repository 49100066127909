import { createSlice } from '@reduxjs/toolkit';
import { Gender } from '../../../enums';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';
import { getCurrentFormValues } from '../../Form';

const initialState = {
    isLoading: true,
    healthcareProvider: {
        acceptsNewPatients: false,
        addresses: [],
    },
};

const slice = createSlice({
    name: 'providerInfo',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setHealthcareProvider: (state, action) => {
            state.healthcareProvider = action.payload;
        },
    },
});

export const { setIsLoading, setHealthcareProvider } = slice.actions;

export default { reducer: slice.reducer, initialState };

// thunks

export const loadHealthcareProvider =
    (healthcareProviderId, prepopulatedProviderData) => async (dispatch, getState) => {
        await dispatch(setIsLoading(true));

        if (healthcareProviderId && healthcareProviderId > 0) {
            const result = await handleApiCall(
                async () => {
                    return await commonService.getProvider({ healthcareProviderId });
                },
                { dispatch },
            );
            if (result.wasSuccessful) {
                const specialty =
                    !!result?.healthcareProvider?.specialty &&
                    !!result?.healthcareProvider?.healthcareProviderSpecialtyId
                        ? {
                              description: result.healthcareProvider.specialty,
                              healthcareProviderSpecialtyId:
                                  result.healthcareProvider.healthcareProviderSpecialtyId,
                          }
                        : null;
                await dispatch(
                    setHealthcareProvider({
                        ...result?.healthcareProvider,
                        gender: Gender.parseChar(result?.healthcareProvider?.gender),
                        specialty,
                    }),
                );
            }
        } else {
            const { healthcareProvider } = getState().providerInfo;
            await dispatch(
                setHealthcareProvider({
                    ...healthcareProvider,
                    ...(prepopulatedProviderData || {}),
                }),
            );
        }

        await dispatch(setIsLoading(false));
    };

export const saveHealthcareProvider =
    (createStubUser = false) =>
    async (dispatch) => {
        //get healthcareProvider without the form keys
        const healthcareProvider = await dispatch(
            getCurrentFormValues({ slice: 'providerInfo', prefix: 'healthcareProvider' }),
        );

        let { addresses, healthcareProviderId } = healthcareProvider;
        const isNewProvider = !(healthcareProviderId > 0);
        const hasAddresses = addresses?.length > 0;

        if (hasAddresses) {
            if (isNewProvider) {
                addresses = addresses.map((address) => ({
                    ...address,
                    addressId: 0,
                    healthcareProviderAddressInfoId: 0,
                    isNew: true,
                }));
            } else {
                addresses = addresses.map((address) => {
                    if (!address.isNew) return address;
                    return {
                        ...address,
                        addressId: 0,
                        healthcareProviderAddressInfoId: 0,
                    };
                });
            }
        }

        const healthcareProviderToSave = {
            ...healthcareProvider,
            gender: Gender.toChar(healthcareProvider.gender),
            addresses,
            createStubUser,
            originClientId: healthcareProvider.originClientId?.clientId,
        };

        const result = await handleApiCall(
            async () => {
                return await commonService.saveProvider(healthcareProviderToSave);
            },
            {
                dispatch,
                mapFormErrors: true,
                prefix: 'healthcareProvider',
                slice: 'providerInfo',
                successMessage: 'Provider saved successfully',
            },
        );
        return result;
    };
