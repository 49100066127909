import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { commonService, commonSlices, wrapRootReducer } from '@cc/common';

// Slices
import auth from 'redux/slices/auth';
import userEnrollment from 'redux/slices/userEnrollment';
import userEnrollmentWizard from './slices/userEnrollmentWizard';
import userEnrollmentWizardAccessTerms from './slices/userEnrollmentWizard/1-userEnrollmentWizardAccessTerms';
import userEnrollmentWizardAccountSetup from './slices/userEnrollmentWizard/2-userEnrollmentWizardAccountSetup';

commonService.setHost(window.environment.API_URL);

const rootReducer = wrapRootReducer(
    combineReducers({
        auth,
        userEnrollment,
        userEnrollmentWizard,
        userEnrollmentWizardAccessTerms,
        userEnrollmentWizardAccountSetup,
        siteDocument: commonSlices.siteDocument.reducer,
    }),
);

const store = configureStore({
    reducer: rootReducer,
});

export default store;
