import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';

const initialState = {
    isLoading: true,
    healthcareProvider: {},
};

const slice = createSlice({
    name: 'providerViewInfo',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setHealthcareProvider: (state, action) => {
            state.healthcareProvider = action.payload;
        },
    },
});

export const { setIsLoading, setHealthcareProvider } = slice.actions;

export default { reducer: slice.reducer, initialState };

// thunks

export const loadHealthcareProvider = (healthcareProviderId) => async (dispatch, getState) => {
    await dispatch(setIsLoading(true));

    const result = await handleApiCall(
        async () => {
            return await commonService.getProvider({ healthcareProviderId });
        },
        { dispatch },
    );

    if (result.wasSuccessful) await dispatch(setHealthcareProvider(result.healthcareProvider));

    await dispatch(setIsLoading(false));

    return result;
};
