import { createSlice, isRejected } from '@reduxjs/toolkit';
import { AuthenticationFailureReason } from '../../enums';

const initialState = {
    errorMessageDialogIsVisible: false,
};

const slice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        setErrorMessage(state, action) {
            if (typeof action.payload === 'object') {
                const { errorObject, description, promptText, variant } = action.payload;

                state.errorMessage = {
                    errorObject,
                    description,
                    promptText,
                    variant,
                };
            } else {
                state.errorMessage = {
                    description: action.payload,
                };
            }
            state.errorMessageDialogIsVisible = true;
        },
        setErrorMessageDialogIsVisibleInternal(state, action) {
            state.errorMessageDialogIsVisible = action.payload;
        },
        clearErrorMessage(state, action) {
            state.errorMessage = undefined;
            state.errorMessageDialogIsVisible = false;
        },
    },
    extraReducers(builder) {
        // catches all errors from rejected createAsyncThunks
        builder.addMatcher(isRejected, (state, action) => {
            const { message, stack } = action.error;
            console.error(
                `An error occurred invoking thunk. Message: '${message}'. Stacktrace: ${stack}`,
            );
            // forward on to main error reducer
            slice.caseReducers.setErrorMessage(state, { ...action, payload: message });
        });
    },
});

export const { setErrorMessage, clearErrorMessage, setErrorMessageDialogIsVisibleInternal } =
    slice.actions;
export const errors = { reducer: slice.reducer, initialState };
export default errors;

//Thunks

export const setErrorMessageDialogIsVisible = (shouldShowDialog) => async (dispatch, getState) => {
    const authFailure = getState().errors.AuthenticationFailure;
    const isNetworkOverlayVisible = authFailure === AuthenticationFailureReason.Error;

    if (isNetworkOverlayVisible) {
        dispatch(setErrorMessageDialogIsVisibleInternal(false));
    } else {
        dispatch(setErrorMessageDialogIsVisibleInternal(shouldShowDialog));
    }
};
