import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { portalAuth as auth, commonSlices, general, notifications, errors } from '../redux/slices';
import { formReducer, isFormActionType } from '../components/Form';
import {
    insertUpdateableDataReducer,
    isUpdateableDataActionType,
} from '../hooks/useInsertUpdateableData';

export const generateStore = () => {
    const staticReducers = getStaticReducers({
        ...commonSlices,
        auth,
        general,
        notifications,
        errors,
    });
    const rootReducer = wrapRootReducer(sortAndCombineReducers(staticReducers, {}));
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    });
    store.asyncReducers = {};
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(
            wrapRootReducer(sortAndCombineReducers(staticReducers, store.asyncReducers)),
        );
    };

    return store;
};

const sortAndCombineReducers = (staticReducers, asyncReducers) => {
    const allReducers = { ...staticReducers, ...asyncReducers };
    const sortedReducers = Object.keys(allReducers)
        .sort()
        .reduce((acc, key) => {
            acc[key] = allReducers[key];
            return acc;
        }, {});
    return combineReducers(sortedReducers);
};

const getStaticReducers = (staticSlices) =>
    Object.keys(staticSlices).reduce((acc, name) => {
        acc[name] = staticSlices[name].reducer;
        return acc;
    }, {});

export const wrapRootReducer = (rootReducer) => {
    const genericReducer = (state = {}, action) => {
        if (
            action.type === 'ADJUST FROM QS' ||
            action.type === 'QS ADJUST COMPLETE' ||
            action.type === 'ADJUST FROM ROUTE'
        ) {
            const { scope, key, value } = action.payload;
            if (key) {
                return {
                    ...state,
                    [scope]: {
                        ...state[scope],
                        [key]: value,
                    },
                };
            }
        }
        if (isFormActionType(action.type)) {
            return formReducer(state, action);
        }
        if (isUpdateableDataActionType(action.type)) {
            return insertUpdateableDataReducer(state, action);
        }
        return rootReducer(state, action);
    };
    return genericReducer;
};

// TODO: refactor existing usage to use createHandleApiAsyncThunk instead
export const extraReducerHelpers = {
    addFetchCase(builder, thunk, callback, isLoadingCallback) {
        if (!isLoadingCallback) isLoadingCallback = (state, value) => (state.isLoading = value);

        builder.addCase(thunk.pending, (state, action) => {
            isLoadingCallback(state, true);
        });
        builder.addCase(thunk.rejected, (state, action) => {
            isLoadingCallback(state, false);
        });
        builder.addCase(thunk.fulfilled, (state, action) => {
            isLoadingCallback(state, false);
            callback(state, action);
        });
    },
};
