import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import { Gender } from '../../enums';
import { commonService } from '../../services/commonServiceInstance';

const MIN_RESULT_SIZE = 20;

const initialState = {
    isLoadingLookups: true,
    isSearching: false,
    lookups: {},
    nonPreferredProviderDialog: {
        isOpen: false,
        provider: null,
        warningMessage: null,
    },
    providerPrintDialog: {
        isOpen: false,
        healthcareProviderId: null,
    },
    providers: null,
    previousProviderIds: [],
    hasMoreResults: false,
    query: {},
    sortBy: null,
    sortDirection: null,
};

const slice = createSlice({
    name: 'advancedProviderSearch',
    initialState,
    reducers: {
        resetState() {
            return { ...initialState };
        },
        resetResults(state) {
            state.providers = null;
            state.previousProviderIds = [];
            state.hasMoreResults = false;
        },
        setIsLoadingLookups(state, action) {
            state.isLoadingLookups = action.payload;
        },
        setIsSearching(state, action) {
            state.isSearching = action.payload;
        },
        setLookups(state, action) {
            state.lookups = action.payload;
        },
        setNonPreferredProviderDialog(state, action) {
            state.nonPreferredProviderDialog = action.payload;
        },
        setProviderPrintDialog(state, action) {
            state.providerPrintDialog = action.payload;
        },
        setProviders(state, action) {
            state.providers = action.payload;
        },
        setPreviousProviderIds(state, action) {
            state.previousProviderIds = action.payload;
        },
        setHasMoreResults(state, action) {
            state.hasMoreResults = action.payload;
        },
        setSort(state, action) {
            const { sortBy, sortDirection } = action.payload;
            state.sortBy = sortBy;
            state.sortDirection = sortDirection;
        },
    },
});

export const {
    resetState,
    resetResults,
    setIsLoadingLookups,
    setIsSearching,
    setLookups,
    setNonPreferredProviderDialog,
    setProviderPrintDialog,
    setProviders,
    setPreviousProviderIds,
    setHasMoreResults,
    setSort,
} = slice.actions;

export const advancedProviderSearch = { reducer: slice.reducer, initialState };
export default advancedProviderSearch;

// Thunks
export const loadLookups =
    ({ patientId, clientId, facilityId }) =>
    async (dispatch, getState) => {
        const { user } = getState().auth;
        await dispatch(setIsLoadingLookups(true));

        const result = await handleApiCall(
            async () =>
                await commonService.getApsLookups({
                    patientId,
                    clientId: clientId ?? user.originClientId ?? user.clients[0]?.clientId,
                    facilityId,
                }),
            {
                dispatch,
                errorMessage: 'Error loading APS data',
            },
        );

        if (result.wasSuccessful) {
            const lookups = { ...result, facilityId };
            delete lookups.wasSuccessful;
            await dispatch(setLookups(lookups));
        }

        await dispatch(setIsLoadingLookups(false));
    };

export const submitQuery =
    ({ patientId, clientId }, fetchingNextPage = false) =>
    async (dispatch, getState) => {
        const { query, previousProviderIds, providers, sortBy, sortDirection, lookups } =
            getState().advancedProviderSearch;
        const { user } = getState().auth;

        if (!fetchingNextPage) dispatch(resetResults());
        dispatch(setIsSearching(true));

        const queryToSend = {
            patientId,
            searchName: query.searchName,
            address: {
                addressId: query.searchNear?.address?.addressId,
                address1: query.address1,
                zipcode: query.zipcode,
            },
            distance: query.distance?.value ?? undefined,
            gender: query.gender || Gender.None,
            insuranceCarrierId: query.insuranceCarrier?.value,
            insurancePlanType: query.insurancePlanType?.value,
            preferredByHealthcareProviderId: query.preferredByProvider?.value,
            insurancePlanSubType: query.insurancePlanSubType?.value,
            language: query.language?.value,
            affiliation: query.affiliation?.value,
            onlyProvidersAcceptingNewPatients: !!query.isAcceptingNewPatients,
            groupByOrganization: !!query.groupByOrganization,
            specialties: query.specialities?.map((speciality) => speciality.name),
            sortBy,
            sortDirection,
            clientId: clientId ?? user.originClientId ?? user.clients[0]?.clientId,
            facilityId: lookups?.facilityId,
            excludePersonIds: fetchingNextPage ? previousProviderIds : [],
            healthcareProviderTagIds: query.providerTags?.map((tag) => tag.value),
            tagFilterType: query?.tagFilterType,
        };

        const result = await handleApiCall(
            async () => await commonService.doApsSearch(queryToSend),
            {
                dispatch,
                errorMessage: 'Error searching for providers.',
            },
        );

        if (result.wasSuccessful) {
            const idsToExclude = getExclusionIds(result.data);
            // the API returns the first page of results in triple (actually pageSize * 1.5 * 3) the size so we must check for that as well.
            // if there are no exactly 20 or 90 results then we know there are more results for infiniteScrollComponent to fetch
            const hasMoreResults =
                result.data.length === MIN_RESULT_SIZE ||
                result.data.length === MIN_RESULT_SIZE * 4.5
                    ? true
                    : false;

            await dispatch(
                setProviders(fetchingNextPage ? [...providers, ...result.data] : result.data),
            );
            await dispatch(
                setPreviousProviderIds(
                    fetchingNextPage ? [...previousProviderIds, ...idsToExclude] : idsToExclude,
                ),
            );
            await dispatch(setHasMoreResults(hasMoreResults));
        } else {
            //this prevents the loader from within InfiniteScroll to appear
            dispatch(setHasMoreResults(false));
        }
        dispatch(setIsSearching(false));
    };

//helpers
const getExclusionIds = (list) => {
    return list.reduce((arr, { items, personId }) => {
        //flatten list of ids if there are organization groupings with sub-items
        const newArr = items ? items.map(({ personId }) => personId) : [personId];

        return [...arr, ...newArr];
    }, []);
};
