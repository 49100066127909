import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import { commonService } from '../../services/commonServiceInstance';

const initialState = {
    isLoading: true,
    providerToPrint: null,
};

const slice = createSlice({
    name: 'providerPrintout',
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setProviderToPrint(state, action) {
            state.providerToPrint = action.payload;
        },
    },
});

export const { setIsLoading, setProviderToPrint } = slice.actions;

export const providerPrintout = { reducer: slice.reducer, initialState };
export default providerPrintout;

// Thunks
export const loadProviderToPrint = (healthcareProviderId) => async (dispatch, getState) => {
    await dispatch(setIsLoading(true));

    const result = await handleApiCall(
        async () => {
            return await commonService.getProvider({ healthcareProviderId });
        },
        {
            dispatch,
            errorMessage: 'Error loading APS data',
        },
    );

    if (result.wasSuccessful) {
        await dispatch(setProviderToPrint(result.healthcareProvider));
    }

    await dispatch(setIsLoading(false));
};
