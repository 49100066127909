import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '@cc/common';
import { ssoService } from 'services/ssoServiceInstance';
import { setFinalResult } from '../userEnrollmentWizard';

const initialState = {
    isLoading: false,
    step2Form: {
        email: '',
        userName: '',
        password: '',
        confirmPassword: '',
    },
};

const slice = createSlice({
    name: 'userEnrollmentWizardAccountSetup',
    initialState: { ...initialState },
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setStep2Form(state, action) {
            const { email, userName } = action.payload;
            state.step2Form.email = email || '';
            state.step2Form.userName = userName || '';
        },
    },
});

export const { setIsLoading, setStep2Form } = slice.actions;

export default slice.reducer;

// Thunks (side-effects)

export const loadStep2 = () => async (dispatch) => {
    await dispatch(setIsLoading(true));

    const result = await handleApiCall(async () => await ssoService.loadUserEnrollmentStep2(), {
        captureFormGeneralError: true,
        dispatch,
        mapFormErrors: true,
    });

    if (result.wasSuccessful) await dispatch(setStep2Form(result));

    await dispatch(setIsLoading(false));
};

export const completeStep2 = () => async (dispatch, getState) => {
    const { email, userName, password } = getState().userEnrollmentWizardAccountSetup.step2Form;
    const request = {
        email,
        userName,
        password,
    };

    const result = await handleApiCall(
        async () => await ssoService.completeUserEnrollmentStep2(request),
        {
            captureFormGeneralError: true,
            dispatch,
            mapFormErrors: true,
            prefix: 'step2Form',
            shouldShowErrorDialog: false,
            slice: 'userEnrollmentWizardAccountSetup',
        },
    );

    if (result.wasSuccessful) await dispatch(setFinalResult(result));

    return result.wasSuccessful;
};
