import React from 'react';
import PropTypes from 'prop-types';
import './ExceptionDetails.scss';

export const ExceptionDetails = ({ error }) => (
    <div className="exception__details">
        <input id="exception__details--collapsible" className="toggle" type="checkbox" />
        <label htmlFor="exception__details--collapsible" className="lbl-toggle">
            More Info
        </label>
        <div className="exception__details--collapsible-content">
            <div className="exception__details--collapsible-content-inner">
                <pre>
                    Name: {error.name}
                    <br />
                    Message: {error.message}
                    <br />
                    Stacktrace: {error.stack}
                </pre>
            </div>
        </div>
    </div>
);

ExceptionDetails.propTypes = {
    error: PropTypes.shape({
        name: PropTypes.string,
        message: PropTypes.string,
        stack: PropTypes.any,
    }),
};
