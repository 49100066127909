import ApiService from './ApiService';

/**
 * Represents a service that holds logic common to all portals.
 */
export default class BaseCommonService extends ApiService {
    constructor(history) {
        super(history);

        this.onPatientAppError = this.onPatientAppError.bind(this);
    }

    onPatientAppError(result) {
        if (result.validationErrors.unauthorized) {
            this.history.replace('/Patients');
        }
    }

    //Auth
    async getContext(data) {
        return await this.get('/api/auth/get-context', data);
    }

    async refreshToken() {
        return await this.post('/api/auth/refresh-token');
    }

    async generatePassword(data) {
        return await this.get('/api/auth/generate-password', data);
    }

    async logout(data) {
        return await this.get('/api/auth/logout', data);
    }

    //Search
    async searchClientFacilities(data) {
        return await this.get('/api/clients/find', data);
    }

    async findPatients(data, noParallelRequests = false) {
        return await this.get('/api/patients/find', data, {
            id: 'searchPatients',
            noParallelRequests,
            allowAbortError: noParallelRequests, // if cancelable, then allow an abort error to be thrown
        });
    }

    async searchUsers(data, noParallelRequests = false) {
        return await this.get('/api/users/find', data, {
            id: 'searchUsers',
            noParallelRequests,
            allowAbortError: noParallelRequests,
        });
    }

    async searchIcd10Diagnoses(data) {
        return await this.get('/api/coded-concepts/diagnoses/icd-10/find', data);
    }

    //TODO: refactor in next round of API enhancments - use SearchUsers
    async searchProviderUsers(data) {
        return await this.get('/api/provider/users/search-providers', data);
    }

    //Advanced Provider Search
    async getApsLookups(data) {
        return await this.get('/api/aps/lookups', data);
    }

    async doApsSearch(data) {
        return await this.post('/api/aps/search', data);
    }

    //Patient related
    async getPatientInfo(data) {
        return await this.get('/api/patients/info', data, null, this.onPatientAppError);
    }

    async getPatientInfoAlt(data) {
        return await this.get('/api/patients/info-alt', data, null, this.onPatientAppError);
    }

    async getPatientCharts(data) {
        return await this.get('/api/patients/charts', data, null, this.onPatientAppError);
    }

    async getPatientEncounters(data) {
        return await this.get('/api/patients/encounters', data);
    }

    async getPatientCareTeam(data) {
        return await this.get('/api/patients/care-team', data, null, this.onPatientAppError);
    }

    async leavePatientCareTeam(data) {
        return await this.post('/api/patients/care-team/leave', data);
    }

    async getPatientAppointments(data) {
        return await this.get('/api/patients/appointments/list', data);
    }

    //Documents
    async getDocumentInfo(data) {
        return await this.get(`/api/document-viewer/${data.patientDocumentId}`);
    }

    async getDocumentText({ patientDocumentId, ...data }) {
        return await this.get(`/api/document-viewer/${patientDocumentId}/text`, data);
    }

    async getAppointmentText(data) {
        return await this.get(`/api/document-viewer/appointment/${data.appointmentId}`);
    }

    async getReferralText(data) {
        return await this.get(`/api/document-viewer/referral/${data.referralId}`);
    }

    async getSummaryOfCareText(data) {
        this.wait(500);
        return await this.get(`/api/document-viewer/summary-of-care/${data.patientId}`);
    }

    async getDemographicsText(data) {
        return await this.get(
            `/api/document-viewer/demographics/${data.patientId}${
                data.patientEncounterId ? '/' + data.patientEncounterId : ''
            }`,
        );
    }

    async getPatientDemographics(data) {
        return await this.get('/api/patients/demographics', data, null, this.onPatientAppError);
    }

    async getPrimaryDocumentTypes() {
        return await this.get(`/api/document-viewer/primary-document-types`);
    }

    async faxDocuments(data) {
        return await this.post(`/api/document-export/fax`, data);
    }

    async directEmailDocuments({
        fromName,
        fromOrganization,
        toName,
        toDirectEmailAddress,
        subject,
        comments,
        patientId,
        charts,
    }) {
        return await this.post(`/api/document-export/directEmail`, {
            fromName,
            fromOrganization,
            toName,
            toDirectEmailAddress,
            subject,
            comments,
            patientId,
            charts,
        });
    }

    //EMR
    async pullDataForPatient(data) {
        return await this.get('/api/integrations/patient/pull', data);
    }

    async pushDocumentsToEmr(data) {
        return await this.post('/api/integrations/patient/push-documents', data);
    }

    //Users providers
    async getUserOrganizations(data) {
        return await this.get('/api/users/organizations', data);
    }

    async getImpersonatableUsers(data) {
        return await this.get('/api/users/impersonatable', data);
    }

    async getSpecialties(data) {
        return await this.get('/api/specialties/find', data);
    }

    //Reporting
    async getPowerBIAvailableReports(data) {
        return await this.get('/api/powerbi/reports', data);
    }

    async getPowerBIEmbeddedReport(data) {
        return await this.get('/api/powerbi/report', data);
    }

    //Other
    async getAllClients(data) {
        return await this.get('/api/clients/all', data);
    }

    async getFacilityHierarchies(data) {
        return this.get('/api/facilities/hierarchies', data);
    }

    async getAddress(data) {
        await this.wait(300);
        return {
            wasSuccessful: true,
            validationErrors: null,
            address: {
                label: 'My Test Address',
                address1: '834 North Linden Drive',
                address2: 'Apt #234',
                city: 'Silver Spring',
                state: 'VA',
                zipcode: '23139',
                locationCode: data.healthcareProviderAddressInfoId ? 'TESTCODE' : '',
            },
        };
    }

    async getProviderAddresses(data) {
        return await this.get('/api/providers/addresses', data);
    }

    async saveAddress(data) {
        await this.wait(300);
        return { wasSuccessful: true, validationErrors: null, address: data };
    }

    async healthcheck() {
        return await this.get('/api/healthcheck/api');
    }

    async healthcheckSlim() {
        return await this.issueSlimRequest('/api/healthcheck/api', 'GET');
    }

    async getSiteAlert(url) {
        // the timestamp is a cache busting mechanism to ensure that the alert is always fetched from the server
        const result = await fetch(url + `?timestamp=${Date.now()}`);
        const alert = await result.json();

        return alert;
    }

    async findAddresses(data) {
        return await this.get('/api/providers/suggested-addresses', data);
    }

    async getAddressSuggestion(data) {
        return await this.get('/api/providers/standardized-addresses', data);
    }
}
