import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const wrapperFor = (Wrapper, staticClassName, variants = []) => {
    class WrapperFor extends React.PureComponent {
        static get name() {
            return staticClassName.replace(new RegExp('-', 'g'), '');
        }

        render() {
            const {
                children,
                className,
                dataTestId,
                id,
                innerRef,
                onClick,
                onMouseOut,
                onMouseOver,
                style,
                title,
            } = this.props;

            const variantClassnames = variants.filter((variant) => {
                if (!Reflect.has(this.props, variant)) return false;

                const variantValue = this.props[variant];

                // Notation: <Element variant />
                if (variantValue === undefined) return true;

                // Notation: <Element variant={true} />
                // Notation: <Element variant="anystring" />
                return variantValue;
            });

            let dataProps = Object.keys(this.props).reduce((obj, key) => {
                // allow data attributes to pass through
                if (key.indexOf('data-') === 0) {
                    obj[key] = this.props[key];
                }
                return obj;
            }, {});

            return (
                <Wrapper
                    {...dataProps}
                    className={classnames(staticClassName, variantClassnames, className)}
                    data-test-id={dataTestId}
                    id={id}
                    onClick={onClick}
                    onMouseOut={onMouseOut}
                    onMouseOver={onMouseOver}
                    ref={innerRef}
                    style={style}
                    title={title}
                >
                    {children}
                </Wrapper>
            );
        }
    }

    WrapperFor.displayName = staticClassName.replace(new RegExp('-', 'g'), '');
    WrapperFor.propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        dataTestId: PropTypes.string,
        id: PropTypes.string,
        innerRef: PropTypes.any,
        onClick: PropTypes.func,
        onMouseOut: PropTypes.func,
        onMouseOver: PropTypes.func,
        style: PropTypes.any,
        title: PropTypes.string,
    };

    return WrapperFor;
};

// commonly used wrappers
export const divWrapperFor = (staticClassName, variants) =>
    wrapperFor('div', staticClassName, variants);
export const spanWrapperFor = (staticClassName, variants) =>
    wrapperFor('span', staticClassName, variants);
export const headerWrapperFor = (staticClassName, variants) =>
    wrapperFor('header', staticClassName, variants);
export const footerWrapperFor = (staticClassName, variants) =>
    wrapperFor('footer', staticClassName, variants);
export const sectionWrapperFor = (staticClassName, variants) =>
    wrapperFor('section', staticClassName, variants);
