import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BrowserNotSupported.scss';

import { faFrown } from '@fortawesome/pro-light-svg-icons';
import { faEdge, faFirefox, faGoogle, faSafari } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrowserNotSupported = ({ hasDarkButtons }) => {
    const linkClasses = classNames('browser-not-supported__link', {
        'browser-not-supported__link--dark': hasDarkButtons,
    });
    return (
        <div className="browser-not-supported">
            <FontAwesomeIcon className="browser-not-supported__icon" icon={faFrown} size="7x" />
            <h3 className="browser-not-supported__sub-header">We're sorry!</h3>
            <h1 className="browser-not-supported__header">Internet Explorer is not supported.</h1>
            <h3 className="browser-not-supported__sub-header">
                For the best experience, please use one of the following browsers:
            </h3>
            <div className="browser-not-supported__link-list">
                <a href="https://www.google.com/chrome/" className={linkClasses}>
                    <FontAwesomeIcon className="browser-not-supported__link-icon" icon={faGoogle} />
                    Chrome
                </a>
                <a href="https://www.mozilla.org/en-US/firefox/new/" className={linkClasses}>
                    <FontAwesomeIcon
                        className="browser-not-supported__link-icon"
                        icon={faFirefox}
                    />
                    Firefox
                </a>
                <a href="https://www.microsoft.com/en-us/edge" className={linkClasses}>
                    <FontAwesomeIcon className="browser-not-supported__link-icon" icon={faEdge} />
                    Edge
                </a>
                <a href="https://support.apple.com/downloads/safari" className={linkClasses}>
                    <FontAwesomeIcon className="browser-not-supported__link-icon" icon={faSafari} />
                    Safari
                </a>
            </div>
        </div>
    );
};

BrowserNotSupported.propTypes = {
    hasDarkButtons: PropTypes.bool,
};

export default BrowserNotSupported;
