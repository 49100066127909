import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import { commonService } from '../../../services/commonServiceInstance';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { isEqual } from 'lodash';

const initialState = {
    isLoading: false,
    searchForm: {
        label: '',
        address1: '',
        address2: '',
        city: '',
        state: null,
        zip: '',
        officePhone: '',
        faxNumber: '',
        locationCode: '',
        combination: false,
    },
    addressResults: null,
    hasSearched: false,
    isLoadingSuggestedAddress: true,
    isSavingAddress: false,
    suggestedAddress: null,
};

const slice = createSlice({
    name: 'addFindAddressDialog',
    initialState,
    reducers: {
        setAddress: (state, action) => {
            state.searchForm = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setAddressResults: (state, action) => {
            state.addressResults = action.payload;
            state.hasSearched = true;
        },
        setPreviousSearch: (state, action) => {
            state.previousSearch = action.payload;
        },
        setHasSearchedFullAddress: (state, action) => {
            state.hasSearchedFullAddress = action.payload;
        },
        setSuggestedAddress: (state, action) => {
            state.suggestedAddress = action.payload;
            state.isLoadingSuggestedAddress = false;
        },
        setIsLoadingSuggestedAddress: (state, action) => {
            state.isLoadingSuggestedAddress = action.payload;
        },
        setIsSavingAddress: (state, action) => {
            state.isSavingAddress = action.payload;
        },
        reset: () => ({
            ...initialState,
        }),
    },
});

export const {
    setAddress,
    setIsLoading,
    setAddressResults,
    setSuggestedAddress,
    setIsLoadingSuggestedAddress,
    setIsSavingAddress,
    setPreviousSearch,
    setHasSearchedFullAddress,
    reset,
} = slice.actions;

export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const searchAddress = () => async (dispatch, getState) => {
    const { searchForm } = getState().addFindAddressDialog;

    dispatch(setIsLoading(true));

    const data = {
        addressLabel: searchForm.label,
        address1: searchForm.address1,
        address2: searchForm.address2,
        city: searchForm.city,
        state: searchForm.state,
        zipcode: searchForm.zipcode,
        officePhone: searchForm.officePhone,
        faxNumber: searchForm.fax,
    };

    const result = await handleApiCall(async () => await commonService.findAddresses(data), {
        dispatch,
    });

    if (result?.wasSuccessful) {
        const addresses = result?.items?.map(
            (
                {
                    address,
                    healthcareProviderAddressInfo,
                    providers,
                    totalAppointments,
                    totalProviders,
                },
                index,
            ) => ({
                ...address,
                addressLabel: healthcareProviderAddressInfo?.addressLabel,
                officePhone: healthcareProviderAddressInfo?.officePhone,
                faxNumber: healthcareProviderAddressInfo?.faxNumber,
                locationCode: healthcareProviderAddressInfo?.locationCode,
                providers,
                totalAppointments,
                totalProviders,
                id: `${address?.address1?.replace(/\s/g, '')}-${index}`,
            }),
        );

        dispatch(setAddressResults(addresses));
        dispatch(setPreviousSearch(data));

        const hasSearchedFullAddress =
            !!data.address1 && !!data.city && !!data.state && !!data.zipcode;
        dispatch(setHasSearchedFullAddress(hasSearchedFullAddress));
    }

    dispatch(setIsLoading(false));
};

export const saveAddress = (address) => async (dispatch, getState) => {
    dispatch(setIsSavingAddress(true));

    const {
        addressId,
        addressLabel,
        label,
        healthcareProviderAddressInfoId,
        healthcareProviderId,
        address1,
        address2,
        city,
        state,
        zip,
        zipcode,
        officePhone,
        fax,
        faxNumber,
        locationCode,
        shouldSaveLocal,
    } = address;

    const data = {
        addressId,
        addressLabel: label || addressLabel,
        healthcareProviderAddressInfoId,
        healthcareProviderId,
        address1,
        address2,
        city,
        state,
        zipcode: zip || zipcode,
        officePhone,
        faxNumber: fax || faxNumber,
        locationCode,
    };

    if (shouldSaveLocal) {
        const date = new Date();
        const tempId = date.getTime();
        return {
            wasSuccessful: true,
            address: {
                ...data,
                isNew: true,
                addressId: data?.addressId || tempId,
                healthcareProviderAddressInfoId: data?.healthcareProviderAddressInfoId || tempId,
            },
        };
    }

    const result = await handleApiCall(async () => await commonService.saveProviderAddress(data), {
        dispatch,
        mapFormErrors: true,
        slice: 'addFindAddressDialog',
        successMessage: 'Address added successfully.',
    });

    if (!result?.wasSuccessful) {
        await dispatch(setIsSavingAddress(false));
        return;
    }

    await dispatch(setIsSavingAddress(false));
    return {
        wasSuccessful: result.wasSuccessful,
        address: {
            ...result?.address,
            isNew: true,
            addressId: result?.address?.addressId,
            healthcareProviderAddressInfoId:
                result?.healthcareProviderAddressInfo?.healthcareProviderAddressInfoId,
            addressLabel: result?.healthcareProviderAddressInfo?.addressLabel,
        },
    };
};

export const getAddressSuggestion = () => async (dispatch, getState) => {
    const { isLoadingSuggestedAddress, searchForm, suggestedAddress } =
        getState().addFindAddressDialog;

    if (!isLoadingSuggestedAddress) dispatch(setIsLoadingSuggestedAddress(true));

    const data = {
        addressLabel: searchForm.label,
        address1: searchForm.address1,
        address2: searchForm.address2,
        city: searchForm.city,
        state: searchForm.state,
        zipcode: searchForm.zipcode,
        officePhone: searchForm.officePhone,
        faxNumber: searchForm.fax,
    };

    if (isEqual(data, suggestedAddress?.prevInput)) {
        dispatch(setIsLoadingSuggestedAddress(false));
        return;
    }

    const result = await handleApiCall(async () => await commonService.getAddressSuggestion(data), {
        dispatch,
        shouldShowErrorDialog: false,
        componentId: 'standardizedAddressSuggestion',
    });

    if (!result?.wasSuccessful) {
        dispatch(setIsLoadingSuggestedAddress(false));
        return;
    }

    //prevInput is used to dedupe and not make multiple calls for the same address
    dispatch(
        setSuggestedAddress({
            ...result.address,
            addressLabel: result.addressLabel,
            officePhone: data.officePhone,
            faxNumber: data.faxNumber,
            prevInput: data,
        }),
    );
};

// Selectors
export const canCreateNewAddressSelector = createSelector(
    (state) => state.addFindAddressDialog.searchForm,
    (state) => state.addFindAddressDialog.previousSearch,
    (searchForm, previousSearch) => {
        const currentForm = {
            addressLabel: searchForm.label,
            address1: searchForm.address1,
            address2: searchForm.address2,
            city: searchForm.city,
            state: searchForm.state,
            zipcode: searchForm.zipcode,
            officePhone: searchForm.officePhone,
            faxNumber: searchForm.fax,
        };

        const formValid =
            currentForm.address1 && currentForm.city && currentForm.state && currentForm.zipcode;
        return JSON.stringify(currentForm) === JSON.stringify(previousSearch) && formValid;
    },
);
