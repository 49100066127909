import React from 'react';
import PropTypes from 'prop-types';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEnvironment } from '../../hooks/useEnvironment';
import { useLazyRef } from '../../hooks/useLazyRef';
import { createAppInsights } from './appInsights';

export const AppInsightsProvider = ({ children, history }) => {
    const env = useEnvironment();

    // this will only be created once, when the component first mounts
    const reactPlugin = useLazyRef(() => createAppInsights(history, env)).current;

    return (
        <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
    );
};

AppInsightsProvider.propTypes = {
    history: PropTypes.any.isRequired,
};
