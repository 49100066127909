import { createSlice } from '@reduxjs/toolkit';
import commonService from '../../services/commonServiceInstance';
import { handleApiCall } from '../../helpers/handleApiCall';

const initialState = {
    document: null,
    isLoading: true,
    nextDocument: null,
    previousDocument: null,
};

const slice = createSlice({
    name: 'siteDocument',
    initialState,
    reducers: {
        reset() {
            return { ...initialState };
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setSiteDocumentData(state, action) {
            const { document, nextDocument, previousDocument } = action.payload;
            state.document = document;
            state.nextDocument = nextDocument;
            state.previousDocument = previousDocument;
        },
    },
});

export const { reset, setIsLoading, setSiteDocumentData } = slice.actions;

export const siteDocument = { reducer: slice.reducer, initialState };
export default siteDocument;

// Thunks (side effects)

export const loadSiteDocument = (siteDocumentId, documentType) => async (dispatch, getState) => {
    await dispatch(setIsLoading(true));

    const result = await handleApiCall(
        async () =>
            await commonService.getSiteDocument({
                siteDocumentId,
                documentType,
                parseMarkdown: true,
            }),
        { dispatch },
    );

    if (result.wasSuccessful) {
        await dispatch(setSiteDocumentData(result));
    }

    await dispatch(setIsLoading(false));
};
