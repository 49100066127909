import React from 'react';

/** function to retry loading a chunk to avoid chunk load error for out of date code or network issues
 *
 * @param {Function} componentImport - normal import function (same used for React.lazy): () => import('./component')
 */

export const lazyWithRetry = (componentImport) =>
    React.lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.sessionStorage.getItem('page-has-been-force-refreshed') || 'false',
        );

        try {
            const component = await componentImport();

            window.sessionStorage.setItem('page-has-been-force-refreshed', 'false');

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Refresh the page immediately.
                window.sessionStorage.setItem('page-has-been-force-refreshed', 'true');
                window.location.reload();

                // Return a dummy component to avoid crashing the application should this complete before the page is reloaded.
                return { default: () => null };
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let the application crash and raise the error.
            throw error;
        }
    });

const timeIntervalMs = 1000;

/** function to retry loading a chunk to avoid chunk load error for out of date code or network issues
 *  will retry loading the chunk with exponential backoff until maxRetries is reached
 * @param {Function} componentImport - normal import function (same used for React.lazy): () => import('./component')
 * @param {Boolean} shouldRetry - flag to toggle retrying
 * @param {Number} maxRetries - number of retries before failing
 */
export const lazyWithRetries = (componentImport, shouldRetry = false, maxRetries = 3) =>
    React.lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.sessionStorage.getItem('page-has-been-force-refreshed') || 'false',
        );

        try {
            const component = await componentImport();

            window.sessionStorage.setItem('page-has-been-force-refreshed', 'false');

            return component;
        } catch (error) {
            //retries with exponential backoff if toggled on
            if (shouldRetry) {
                await new Promise((resolve) => setTimeout(resolve, timeIntervalMs));

                for (let i = 0; i < maxRetries; i++) {
                    try {
                        return await componentImport();
                    } catch (e) {
                        //skip last delay, jump right to refresh logic
                        await new Promise((resolve) =>
                            setTimeout(
                                resolve,
                                i === maxRetries - 1 ? 0 : timeIntervalMs * Math.pow(2, i),
                            ),
                        );
                    }
                }
            }

            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Refresh the page immediately.
                window.sessionStorage.setItem('page-has-been-force-refreshed', 'true');

                window.location.reload();

                // Return a dummy component to avoid crashing the application should this complete before the page is reloaded.
                return { default: () => null };
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let the application crash and raise the error. (also auto-rejects the promise)
            throw error;
        }
    });
