/**
 * Super-simple browser-based event bus.
 * NOTE: use sparingly as usage can be hard to debug! Intended use is for
 * cross cutting concerns where the parts of the sytem that are disconnected
 * need to communicate. For example, API services communicating global errors
 * like 'unauthorized' back to components.
 */
class EventEmitter {
    on = (eventName, callback) => window.addEventListener(eventName, callback, false);
    off = (eventName, callback) => window.removeEventListener(eventName, callback, false);
    emit = (eventName, data) => window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
}

const eventEmitter = new EventEmitter();
export default eventEmitter;
