import BaseCommonService from './BaseCommonService';

class CommonService extends BaseCommonService {
    constructor(history) {
        super(history);
    }
    // Generated (templates/react/api-service.cshtml)

    /////////////////////////////////////////
    // ClientOrganizations Module
    /////////////////////////////////////////

    async listClientOrganizations(data) {
        const url = '/api/client-orgs/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveClientOrganization(data) {
        const url = '/api/client-orgs/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // PatientCorrespondences Module
    /////////////////////////////////////////

    async listPatientCorrespondence(data) {
        const url = '/api/patient-correspondence/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Clients Module
    /////////////////////////////////////////

    async listClients(data) {
        const url = '/api/clients/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveClient(data) {
        const url = '/api/clients/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Facilities Module
    /////////////////////////////////////////

    async getFacilityHierarchies(data) {
        const url = '/api/facilities/hierarchies';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async listFacilities(data) {
        const url = '/api/facilities/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveFacility(data) {
        const url = '/api/facilities/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getFaxNumbers(data) {
        const url = '/api/facilities/fax-numbers';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async provisionNewFaxNumber(data) {
        const url = '/api/facilities/provision-new-fax-number';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getAreaCodes(data) {
        const url = '/api/facilities/get-area-codes';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Faxes Module
    /////////////////////////////////////////

    async receiveIncomingFax(data) {
        const url = '/api/faxes/receive';
        // Start Custom
        return null; // THIS ENDPOINT IS A WEBHOOK; NOT INTENDED TO BE CALLED BY FRONTEND
        // End Custom
    }

    async updateFaxStatus(data) {
        const url = '/api/faxes/status';
        // Start Custom
        return null; // THIS ENDPOINT IS A WEBHOOK; NOT INTENDED TO BE CALLED BY FRONTEND
        // End Custom
    }

    /////////////////////////////////////////
    // Markets Module
    /////////////////////////////////////////

    async listMarkets(data) {
        const url = '/api/markets/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveMarket(data) {
        const url = '/api/markets/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // HealthConditions Module
    /////////////////////////////////////////

    async listHealthConditions(data) {
        const url = '/api/health-conditions/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getHealthCondition(data) {
        const url = '/api/health-conditions/';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveHealthCondition(data) {
        const url = '/api/health-conditions/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async archiveHealthCondition(data) {
        const url = '/api/health-conditions/archive';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // HealthcareProviderSpecialties Module
    /////////////////////////////////////////

    async listHealthcareProviderSpecialties(data) {
        const url = '/api/specialties/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getHealthcareProviderSpecialty(data) {
        const url = '/api/specialties/';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveHealthcareProviderSpecialty(data) {
        const url = '/api/specialties/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async deleteHealthcareProviderSpecialty(data) {
        const url = '/api/specialties/delete';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // UserTypes Module
    /////////////////////////////////////////

    async listUserTypes(data) {
        const url = '/api/user-types/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getUserType(data) {
        const url = '/api/user-types/';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveUserType(data) {
        const url = '/api/user-types/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // SiteInfo Module
    /////////////////////////////////////////

    async getSiteDocument(data) {
        const url = '/api/site-info/';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getSiteDocuments(data) {
        const url = '/api/site-info/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveSiteDocument(data) {
        const url = '/api/site-info/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Patients Module
    /////////////////////////////////////////

    async getAppointments(data) {
        const url = '/api/patients/appointments/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getPatientEncounterClientConfig(data) {
        const url = '/api/patients/encounter-client-configuration';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getActivities(data) {
        const url = '/api/patients/activities/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getActivitiesListFilters(data) {
        const url = '/api/patients/activities/list/filters';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async setActivitiesListFilters(data) {
        const url = '/api/patients/activities/list/filters';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getPopulations(data) {
        const url = '/api/patients/populations/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getRecentViewers(data) {
        const url = '/api/patients/recent-viewers';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async searchPatients(data) {
        const url = '/api/patients/search';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getPatientEncounter(data) {
        const url = '/api/patients/encounter';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getPatientEncounterClientConfig(data) {
        const url = '/api/patients/encounter-client-configuration';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async savePatientEncounter(data) {
        const url = '/api/patients/save-encounter';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getBaseDemography(data) {
        const url = '/api/patients/base-demography';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async savePatientDemography(data) {
        const url = '/api/patients/save-demography';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async savePatientInsurance(data) {
        const url = '/api/patients/save-insurance';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async deletePatientInsurance(data) {
        const url = '/api/patients/delete-insurance';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async saveGuarantor(data) {
        const url = '/api/patients/save-guarantor';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Sms Module
    /////////////////////////////////////////

    async twilioReply(data) {
        const url = '/api/notifications/sms/twilio-reply';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async twilioStatus(data) {
        const url = '/api/notifications/sms/twilio-status';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async isPhoneNumberOptedOut(data) {
        const url = '/api/notifications/sms/is-phone-number-opted-out';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async sendPatientSms(data) {
        const url = '/api/notifications/sms/send';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // CareTeams Module
    /////////////////////////////////////////

    async joinCareTeam(data) {
        const url = '/api/care-teams/join';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async isOnCareTeam(data) {
        const url = '/api/care-teams/is-member';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async inviteToCareTeam(data) {
        const url = '/api/care-teams/invite';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async leaveCareTeam(data) {
        const url = '/api/care-teams/leave';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async removeCareTeamMember(data) {
        const url = '/api/care-teams/remove-member';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getCareTeamMembers(data) {
        const url = '/api/care-teams/members';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async setCareTeamMemberInactive(data) {
        const url = '/api/care-teams/set-member-inactive';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async setCareTeamMemberRelationshipType(data) {
        const url = '/api/care-teams/set-member-relationship-type';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async updatePatientPcp(data) {
        const url = '/api/care-teams/update-patient-pcp';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Users Module
    /////////////////////////////////////////

    async getSpecialtiesAndUserTypes(data) {
        const url = '/api/users/specialties-and-user-types';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async listUsers(data) {
        const url = '/api/users/list';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async editUserPermissions(data) {
        const url = '/api/users/{userId}/permissions/edit';
        // Start Custom
        return await this.get(url.replace('{userId}', data.userId), data);
        // End Custom
    }

    async saveUserPermissions(data) {
        const url = '/api/users/{userId}/permissions';
        // Start Custom
        return await this.post(url.replace('{userId}', data.userId), data);
        // End Custom
    }

    async getSuggestedUserNotifications(data) {
        const url = '/api/users/notifications/suggested';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getUserTemplateItems(data) {
        const url = '/api/users/template-items';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async viewAgreement(data) {
        const url = '/api/users/view-agreement';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async makeProviderAUser(data) {
        const url = '/api/users/make-provider-a-user';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async generateUsername(data) {
        const url = '/api/users/generate-username';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Appointments Module
    /////////////////////////////////////////

    async getAppointment(data) {
        const url = '/api/appointments/get';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async updateArchiveStatus(data) {
        const url = '/api/appointments/update-archive-status';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async sendEmailReminder(data) {
        const url = '/api/appointments/send-email-reminder';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async sendSmsReminder(data) {
        const url = '/api/appointments/send-sms-reminder';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async cancelSmsReminder(data) {
        const url = '/api/appointments/cancel-sms-reminder';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async cancelSmsConfirmation(data) {
        const url = '/api/appointments/cancel-sms-confirmation';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async saveAppointment(data) {
        const url = '/api/appointments/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Tasks Module
    /////////////////////////////////////////

    async getTask(data) {
        const url = '/api/tasks/task';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getTaskEncounterActivity(data) {
        const url = '/api/tasks/activities';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getTasks(data) {
        const url = '/api/tasks/tasks';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getVisitTasks(data) {
        const url = '/api/tasks/visit-tasks';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getTaskList(data) {
        const url = '/api/tasks/task-list';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async saveTask(data) {
        const url = '/api/tasks/save-task';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getTaskComments(data) {
        const url = '/api/tasks/comments';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getTaskCommentsList(data) {
        const url = '/api/tasks/comments-list';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getTags(data) {
        const url = '/api/tasks/tags';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async updateTaskArchived(data) {
        const url = '/api/tasks/set-archived';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Populations Module
    /////////////////////////////////////////

    async setActiveCandidate(data) {
        const url = '/api/populations/set-active-candidate';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async validatePostActions(data) {
        const url = '/api/populations/validate-post-actions';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // PopulationActivities Module
    /////////////////////////////////////////

    async cancelPopulationActivity(data) {
        const url = '/api/populations/activities/cancel';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getRecentImports(data) {
        const url = '/api/populations/activities/recent-imports';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Providers Module
    /////////////////////////////////////////

    async saveProviderAddress(data) {
        const url = '/api/providers/save-provider-address';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getProviderAddress(data) {
        const url = '/api/providers/get-provider-address';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getProvider(data) {
        const url = '/api/providers/get';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async saveProvider(data) {
        const url = '/api/providers/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async searchProviders(data) {
        const url = '/api/providers/search';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getProviderTags(data) {
        const url = '/api/providers/get-provider-tags';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Workflows Module
    /////////////////////////////////////////

    async listWorkflows(data) {
        const url = '/api/workflows/list';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getWorkflow(data) {
        const url = '/api/workflows/get';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async cloneNewWorkflow(data) {
        const url = '/api/workflows/new-workflow-from';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async createNewEmptyWorkflowVersion(data) {
        const url = '/api/workflows/new-empty-version';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async publishWorkflow(data) {
        const url = '/api/workflows/publish';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async unpublishWorkflow(data) {
        const url = '/api/workflows/unpublish';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async saveWorkflow(data) {
        const url = '/api/workflows/save';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async saveWorkflowNickname(data) {
        const url = '/api/workflows/save-nickname';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async saveWorkflowLog(data) {
        const url = '/api/workflows/save-log';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getWorkflowConditionGroupLogs(data) {
        const url = '/api/workflows/condition-group-logs';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // WorkQueues Module
    /////////////////////////////////////////

    async getWorkQueueEncounters(data) {
        const url = '/api/work-queues/items';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getCategorizedWorkQueues(data) {
        const url = '/api/work-queues/categorized';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Developers Module
    /////////////////////////////////////////

    async getQueryHistory(data) {
        const url = '/api/developers/queries/history';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Insurance Module
    /////////////////////////////////////////

    async getInsurancePlans(data) {
        const url = '/api/insurance/get-plans';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Messages Module
    /////////////////////////////////////////

    async getSingleUserConversation(data) {
        const url = '/api/messages/conversation';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async archiveUserConversation(data) {
        const url = '/api/messages/archive-conversation';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getUserConversations(data) {
        const url = '/api/messages/conversations';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async sendMessage(data) {
        const url = '/api/messages/conversations';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getUserConversationMessages(data) {
        const url = '/api/messages/';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async sendQuickReply(data) {
        const url = '/api/messages/';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    async getUnreadMessageCount(data) {
        const url = '/api/messages/unreadcount';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getConversationPage(data) {
        const url = '/api/messages/conversation-page';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    async getMessagePage(data) {
        const url = '/api/messages/message-page';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Documents Module
    /////////////////////////////////////////

    async uploadDocument(data, files, onProgress) {
        const url = '/api/documents/upload';
        // Start Custom
        return await this.postMultipartForm(url, data, files, onProgress);
        // End Custom
    }

    /////////////////////////////////////////
    // Healthcheck Module
    /////////////////////////////////////////

    async getHealthDatabase(data) {
        const url = '/api/healthcheck/database';
        // Start Custom
        return await this.get(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Notifications Module
    /////////////////////////////////////////

    async sendSupportRequest(data) {
        const url = '/api/notifications/support-request';
        // Start Custom
        return await this.post(url, data);
        // End Custom
    }

    /////////////////////////////////////////
    // Admin Module
    /////////////////////////////////////////

    async findUsers(data) {
        return await this.get('/api/admin/users', data);
    }
}

export default CommonService;
