import throttle from 'lodash/throttle';
import merge from 'lodash/merge';
import parseISO from 'date-fns/parseISO';
import { getUserIdFromToken } from './tokens';

const dateRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

const getInitStore = (slices) => {
    return Object.keys(slices).reduce((acc, name) => {
        acc[name] = slices[name].initialState;
        return acc;
    }, {});
};

export const getValuesFromLocalStorage = (options) => {
    const localStorageKey = getReduxStoreKey();
    const json = localStorage[localStorageKey];

    if (json) {
        try {
            const parsed = JSON.parse(json, (key, value) => {
                // if value is a serialized date, convert it to a date object
                if (dateRegex.test(value)) {
                    const parsedDate = parseISO(value);
                    if (!isNaN(parsedDate)) return new Date(parsedDate);
                }
                return value;
            });

            if (parsed[options.name]) {
                const initialState = merge({}, options.initialState, parsed[options.name]);
                return { ...options, initialState };
            }
        } catch (e) {
            console.error(e);
        }
    }

    return options;
};

const getPrefixObject = (state, prefix) => {
    const pieces = prefix.split('.');
    let obj = state;
    for (let i = 0; i < pieces.length; i++) {
        if (!obj[pieces[i]]) return obj[pieces[i]];
        obj = obj[pieces[i]];
    }
    return obj;
};

const buildObjectFromPrefixes = (obj) => {
    let newObj = {};
    Object.entries(obj).forEach((value) => {
        const prefix = value[0];
        const prefixObj = value[1];
        const pieces = prefix.split('.');
        let lastObj = newObj;
        for (let i = 0; i < pieces.length; i++) {
            lastObj[pieces[i]] = lastObj[pieces[i]] || {};
            if (i === pieces.length - 1) {
                if (Array.isArray(prefixObj)) {
                    lastObj[pieces[i]] = [...prefixObj];
                } else if (prefixObj instanceof Object) {
                    lastObj[pieces[i]] = { ...prefixObj };
                } else {
                    lastObj[pieces[i]] = prefixObj;
                }
            }
            lastObj = lastObj[pieces[i]];
        }
    });
    return newObj;
};

/*
 * Example usage:
 * persistToLocalStorage(store, ['slice1.someProp', 'slice2', 'slice3.someProp.anotherProp']);
 */
export const persistToLocalStorage = (store, prefixes) => {
    const localStorageKey = getReduxStoreKey();
    const currentValues = {};
    store.subscribe(
        throttle(function () {
            let shouldUpdate = false;
            if (prefixes && prefixes.length > 0) {
                const state = store.getState();

                prefixes.forEach((prefix) => {
                    const sliceName = prefix.split('.')[0];
                    // Now that slices can be dynamically loaded, we have to check that each
                    // slice exists in the state before comparing values.
                    if (Object.keys(state).includes(sliceName)) {
                        const previousValue = currentValues[prefix];
                        currentValues[prefix] = getPrefixObject(state, prefix);
                        if (previousValue !== currentValues[prefix]) {
                            shouldUpdate = true;
                        }
                    }
                });
            }

            if (shouldUpdate) {
                let storeToPersist = buildObjectFromPrefixes(currentValues);
                try {
                    const prevStore = localStorage.getItem(localStorageKey) || null;
                    const newStore = prevStore
                        ? { ...JSON.parse(prevStore), ...storeToPersist }
                        : storeToPersist;
                    const json = JSON.stringify(newStore);
                    localStorage[localStorageKey] = json;
                } catch (err) {
                    console.error('Failed to persist state to localStorage.', err);
                }
            }
        }, 1000),
    );
};

export const getReduxStoreKey = () => {
    const portalName = window.environment.PORTAL_NAME;
    const userId = getUserIdFromToken() ?? 'anonymous';
    const key = `LOCALSTORAGE_REDUX_STORE_${portalName}_${userId}`;
    return key;
};
