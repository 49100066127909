import { createSlice } from '@reduxjs/toolkit';
import { ssoService } from 'services/ssoServiceInstance';
import { handleApiCall } from '@cc/common';

const initialState = {
    agreementTitle: null,
    agreementText: null,
    accessibleFacilities: null,

    step1Form: {
        firstName: '',
        lastName: '',
        accepted: null,
    },
};

const slice = createSlice({
    name: 'userEnrollmentWizardAccessTerms',
    initialState: { ...initialState },
    reducers: {
        setStep1Data(state, action) {
            const { agreementTitle, agreementText, accessibleFacilities } = action.payload;
            state.agreementTitle = agreementTitle;
            state.agreementText = agreementText;
            state.accessibleFacilities = accessibleFacilities;
        },
    },
});

export const { setStep1Data } = slice.actions;

export default slice.reducer;

// Thunks (side effects)

export const loadStep1 = (result) => async (dispatch) => {
    await dispatch(setStep1Data(result));
};

export const completeStep1 = () => async (dispatch, getState) => {
    const { firstName, lastName } = getState().userEnrollmentWizardAccessTerms.step1Form;

    var request = {
        acceptedFirstName: firstName,
        acceptedLastName: lastName,
    };
    const result = await handleApiCall(
        async () => await ssoService.completeUserEnrollmentStep1(request),
        {
            captureFormGeneralError: true,
            dispatch,
            mapFormErrors: true,
            prefix: 'step1Form',
            slice: 'userEnrollmentWizardAccessTerms',
            shouldShowErrorDialog: false,
        },
    );

    return result.wasSuccessful;
};
