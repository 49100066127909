import differenceInSeconds from 'date-fns/differenceInSeconds';
import { captureException } from '@sentry/react';
import { getAppInsightsInstance } from '../components/AppInsightsProvider/appInsights';
import ServerError from '../services/ServerError';

export const checkForNetworkError = (error) => {
    if (!(error instanceof TypeError)) return false;

    if (!error.message) return false;

    const networkErrorMsgs = [
        'Failed to fetch',
        'NetworkError when attempting to fetch resource',
        'Network request failed',
    ];
    return networkErrorMsgs.some((errMsg) => error.message.indexOf(errMsg) >= 0);
};

export function generateNewErrorCode(isTimeoutError) {
    const totalSeconds = differenceInSeconds(new Date(), new Date('1/1/1970'));
    return 'FE-' + totalSeconds.toString(36).toUpperCase() + (isTimeoutError ? '-TO' : ''); // base36 encode
}

let prevErrorHash = null;

export function reportError(error) {
    if (!error) return;
    // check environment variables to see if error reporting is enabled
    const sentryEnabled = !!window.environment?.SENTRY?.ENABLED;
    const appInsightsEnabled = !!window.environment?.APPINSIGHTS?.ENABLED;
    const serverErrorReportingEnabled =
        !!window.environment?.SENTRY?.SERVER_ERROR_REPORTING_ENABLED;

    // check for backend error code, and if the error is a validationError, or timeoutError
    const { responseJson, url } = error?.errorObject || {};
    const serverErrorCode = responseJson?.errorCode;
    const isValidationError = error?.errorObject?.name === 'ValidationError';
    const isTimeoutError = !!error?.errorObject?.isTimeoutError;

    // If error is a validationError (handled by form flow) don't report at all
    if (isValidationError) return;

    // generate a new error code if one doesn't exist
    let frontendErrorCode;
    if (!serverErrorCode && !isValidationError) {
        frontendErrorCode = generateNewErrorCode(error?.errorObject?.isTimeoutError);
    }
    const errorCode = serverErrorCode || frontendErrorCode;
    const isBackendError = !!serverErrorCode || isTimeoutError;

    // parse out a more useful error message which will be sent and also used for de-duping
    const { message, validationErrors } = responseJson || {};
    const validationErrorsConcat = validationErrors
        ? Object.keys(validationErrors)
              ?.map((key) => `${key}: ${validationErrors[key]}`)
              ?.join(', ')
        : null;

    const dedupingHash = `${message ?? ''}-${validationErrorsConcat ?? ''}-${url ?? ''}-${
        errorCode ?? ''
    }`;

    // don't report anything if the error is the same as the previous error (de-duped by comparing hashes)
    if (prevErrorHash === dedupingHash) return errorCode;

    // get the actyal error object to report if its there
    let errorToCapture = error?.errorObject || error?.description || error;

    // replace default message if there is a better one
    if (errorToCapture?.message === ServerError.DefaultErrorMessage && !!message)
        error.errorObject.message = `${message ?? ''}: ${validationErrorsConcat ?? ''}`;

    //report to app insights if enabled
    let appInsights;
    if (appInsightsEnabled) {
        appInsights = getAppInsightsInstance();
        appInsightsEnabled &&
            appInsights.handleException(errorToCapture, { errorCode, isBackendError });
    }

    // report to sentry if enabled
    // if serverErrorReportingEnabled is false, only report if the error is not a backend error
    if (sentryEnabled && (serverErrorReportingEnabled || !isBackendError)) {
        captureException(errorToCapture, { tags: { errorCode, isBackendError } });
    }

    prevErrorHash = dedupingHash;

    return errorCode;
}
