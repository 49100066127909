import { createSlice } from '@reduxjs/toolkit';
import commonService from '../../services/commonServiceInstance';
import { handleApiCall } from '../../helpers/handleApiCall';

const initialState = {
    insurances: [],
    isLoading: true,
};

const slice = createSlice({
    name: 'insuranceCollapseTree',
    initialState,
    reducers: {
        setInsurances(state, action) {
            state.insurances = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
});

export const { setInsurances, setIsLoading } = slice.actions;

export const insuranceCollapseTree = { reducer: slice.reducer, initialState };
export default insuranceCollapseTree;

// Thunks (side effects)

export const getAllInsurances = () => async (dispatch) => {
    const result = await handleApiCall(async () => await commonService.getAllInsurances(), {
        dispatch,
    });

    if (result.wasSuccessful) {
        await dispatch(setInsurances(result.insurances));
    }

    dispatch(setIsLoading(false));
};
