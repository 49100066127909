import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';
import { mockFindOrgResults } from './mockFindOrgResults';

const initialState = {
    searchForm: {
        name: '',
        npiNumber: '',
    },
    hasSearched: false,
    searchedValues: null,
    isLoadingSearchResults: false,
    searchResults: [],
    hasNpiMatch: false,
};

const slice = createSlice({
    name: 'addFindOrganization',
    initialState,
    reducers: {
        setHasSearched: (state, action) => {
            state.hasSearched = action.payload;
        },
        setSearchedValues: (state, action) => {
            state.searchedValues = action.payload;
        },
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
        },
        setHasNpiMatch: (state, action) => {
            state.hasNpiMatch = action.payload;
        },
        setOrganizationToAdd: (state, action) => {
            state.organizationToAdd = action.payload;
        },
        setIsLoadingSearchResults: (state, action) => {
            state.isLoadingSearchResults = action.payload;
        },
        reset: () => {
            return { ...initialState };
        },
    },
});

export const {
    setHasSearched,
    setSearchedValues,
    setSearchResults,
    setHasNpiMatch,
    setOrganizationToAdd,
    setIsLoadingSearchResults,
    reset,
} = slice.actions;

export default { reducer: slice.reducer, initialState };

// Thunks (side effects)

export const findOrganizations = () => async (dispatch, getState) => {
    await dispatch(setHasSearched(true));
    await dispatch(setIsLoadingSearchResults(true));

    const { searchForm } = getState().addFindOrganization;
    const { name, npiNumber } = searchForm;

    await dispatch(setSearchedValues({ name, npiNumber }));

    // const result = await handleApiCall(
    //     async () => {
    //         return await commonService.searchProviders({ firstName, lastName, npiNumber })
    //     },
    //     { dispatch }
    // );
    //TODO: uncomment when API is ready
    const result =
        name === 'nomatch' ? { wasSuccessful: true, healthcareProviders: [] } : mockFindOrgResults;

    if (!result.wasSuccessful) return;

    await dispatch(setSearchResults(result.healthcareProviders));
    await dispatch(setIsLoadingSearchResults(false));
};

// End Custom
