import { createSlice } from '@reduxjs/toolkit';
import { formatPhoneIfValid } from '../../../helpers/formatHelpers';
import { handleApiCall } from '../../../helpers/handleApiCall';
import { commonService } from '../../../services/commonServiceInstance';

const initialState = {
    isLoading: false,
    smsForm: {
        isOptedOut: false,
        dateOptedOut: null,
        recipientName: '',
        phoneNumber: '',
        messageText: '',
        hasConsentedToSms: false,
        messageContainsNoPhi: false,
    },
};

const slice = createSlice({
    name: 'sendPatientSms',
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setIsOptedOut(state, action) {
            const { isOptedOut, dateOptedOut } = action.payload;
            state.smsForm.isOptedOut = isOptedOut;
            state.smsForm.dateOptedOut = dateOptedOut;
        },
        setPhoneNumber(state, action) {
            state.smsForm.phoneNumber = action.payload;
        },
    },
});

export const { setIsLoading, setIsOptedOut, setPhoneNumber } = slice.actions;

export default { reducer: slice.reducer, initialState };

// thunks

export const initializeSmsForm = (initialNumber) => async (dispatch, getState) => {
    await dispatch(setIsLoading(true));
    await dispatch(setPhoneNumber(formatPhoneIfValid(initialNumber)));

    const { wasSuccessful, isOptedOut, dateOptedOut } = await handleApiCall(
        () => commonService.isPhoneNumberOptedOut({ phoneNumber: initialNumber }),
        {
            dispatch,
            mapFormErrors: true,
            prefix: 'smsForm',
            slice: 'sendPatientSms',
        },
    );

    if (!wasSuccessful) {
        await dispatch(setIsLoading(false));
        return;
    }

    await dispatch(setIsOptedOut({ isOptedOut, dateOptedOut }));
    await dispatch(setIsLoading(false));
};

export const checkForOptOut = (phoneNumber) => async (dispatch, getState) => {
    await dispatch(setIsLoading(true));

    const { wasSuccessful, isOptedOut, dateOptedOut } = await handleApiCall(
        async () => await commonService.isPhoneNumberOptedOut({ phoneNumber }),
        {
            dispatch,
            mapFormErrors: true,
            prefix: 'smsForm',
            slice: 'sendPatientSms',
        },
    );

    if (!wasSuccessful) {
        await dispatch(setIsLoading(false));
        return false;
    }

    let stateWasUpdated = false;

    const existingIsOptedOut = getState().sendPatientSms.smsForm.isOptedOut;
    if (existingIsOptedOut !== isOptedOut) {
        await dispatch(setIsOptedOut({ isOptedOut, dateOptedOut }));
        stateWasUpdated = true;
    }

    await dispatch(setIsLoading(false));
    return stateWasUpdated;
};

export const sendPatientSms = (patientId) => async (dispatch, getState) => {
    const { recipientName, phoneNumber, messageText, messageContainsNoPhi, hasConsentedToSms } =
        getState().sendPatientSms.smsForm;

    const data = {
        patientId: parseInt(patientId),
        recipientName,
        phoneNumber,
        messageText,
        messageContainsNoPhi,
        hasConsentedToSms,
    };
    const result = await handleApiCall(async () => await commonService.sendPatientSms(data), {
        captureFormGeneralError: true,
        dispatch,
        mapFormErrors: true,
        prefix: 'smsForm',
        slice: 'sendPatientSms',
        successMessage: 'SMS sent successfully',
    });

    return result.wasSuccessful;
};
