import { createSlice } from '@reduxjs/toolkit';
import { ssoService } from 'services/ssoServiceInstance';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { getCookieDomain } from '@cc/common';

const LOGIN_ID_KEY = 'LOGINID';
const LAST_LOGIN_ID = 'LASTLOGINID';
const { API_URL, LEGACY_APP_URL, PROVIDER_PORTAL_URL } = window.environment;

ssoService.setHost(API_URL);
const parsedQueryString = queryString.parse(window.location.search);
const { returnUrl, changePasswordId } = parsedQueryString;
const returnUrlIsInvalid = 'returnUrl' in parsedQueryString && returnUrl?.indexOf('http') !== 0;
const domain = getCookieDomain();
const tokenKey = `Token${window.environment.ENVIRONMENT}`;
const portalKey = `Portal${window.environment.ENVIRONMENT}`;

// set up token resolver to fetch token from cookie (single source of truth)
ssoService.setTokenResolver(() => {
    const cookieToken = Cookies.get(tokenKey, { domain });
    return cookieToken;
});

const slice = createSlice({
    name: 'auth',
    initialState: {
        currentStep: 'login',
        loginId: window.localStorage[LOGIN_ID_KEY] || '',
        lastLoginId: window.localStorage[LAST_LOGIN_ID] || '',
        forgotPasswordRequestFailed: false,
        isRememberMe: !!window.localStorage[LOGIN_ID_KEY],
        returnUrl: returnUrl,
        returnUrlIsInvalid: returnUrlIsInvalid,
        changePasswordId: changePasswordId,
        friendlyKickoutMessage: null,
        genericLoginMessage: null,
        loggedOutInactive: false,
        user: null,
        healthcareOrganization: null,
        clientOrganization: null,
        changePassword: {
            password: null,
            password2: null,
        },
        forgotPassword: {
            loginId: null,
        },
    },
    reducers: {
        setLoginId(state, action) {
            state.loginId = action.payload;
            state.isLocalLogin = undefined;
            state.provider = undefined;
            state.password = undefined;

            if (state.isRememberMe) {
                window.localStorage[LOGIN_ID_KEY] = action.payload;
            }
        },

        setUser(state, action) {
            state.user = action.payload;
        },

        clearUser(state) {
            state.user = null;
        },

        setGenericLoginMessage(state, action) {
            state.genericLoginMessage = action.payload;
        },

        setFriendlyKickoutMessage(state, action) {
            state.friendlyKickoutMessage = action.payload;
        },

        clearFriendlyKickoutMessage(state, action) {
            state.friendlyKickoutMessage = undefined;
        },

        setPassword(state, action) {
            state.password = action.payload;
        },

        setToken(state, action) {
            const token = action.payload;
            state.token = token;
            Cookies.set(tokenKey, token, { domain });
        },

        setExpiryTimeoutToken(state, action) {
            state.expiryTimeoutToken = action.payload;
        },

        setLoggedOutInactive(state, action) {
            state.loggedOutInactive = action.payload;
        },

        clearExpiryTimeoutToken(state, action) {
            state.expiryTimeoutToken = null;
        },

        clearToken(state) {
            Cookies.remove(tokenKey);
            state.token = null;
        },

        setReturnUrl(state, action) {
            if (!state.returnUrl) {
                state.returnUrl = action.payload;
            }
        },

        clearReturnUrl(state) {
            state.returnUrl = undefined;
        },

        setRouteLogin(state, action) {
            const { isLocalLogin, provider, returnUrl } = action.payload;
            state.isLocalLogin = isLocalLogin;
            state.provider = provider;

            if (!state.returnUrl) {
                state.returnUrl = returnUrl;
            }
        },

        setAuthenticationFailure(state, action) {
            if (typeof action.payload === 'string') {
                state.authenticationFailure = action.payload;
                state.loginFailureReason = null;
                return;
            }

            state.authenticationFailure = action.payload.authenticationFailure;
            state.loginFailureReason = action.payload.loginFailureReason;
        },

        setRememberMe(state, action) {
            const { loginId } = state;
            state.isRememberMe = action.payload;
            window.localStorage[LOGIN_ID_KEY] = action.payload ? loginId : '';
        },

        setLastLoginId(state, action) {
            const { loginId } = state;
            window.localStorage[LAST_LOGIN_ID] = action.payload ? loginId : '';
        },

        setForgotPasswordMessage(state, action) {
            state.forgotPasswordMessage = action.payload;
        },

        setForgotPasswordRequestFailed(state, action) {
            state.forgotPasswordRequestFailed = action.payload;
        },

        setChangePasswordMessage(state, action) {
            state.changePasswordMessage = action.payload;
        },

        setTwoFactorMessage(state, action) {
            state.twoFactorMessage = action.payload;
        },

        hideChangePassword(state, action) {
            state.hideChangePassword = action.payload;
        },

        setTwoFactorAuthenticationOptions(state, action) {
            state.twoFactorAuthenticationOptions = action.payload;
        },

        setShouldValidateTwoFactorAuthentication(state, action) {
            state.shouldValidateTwoFactorAuthentication = action.payload;
        },

        setSelectedTwoFactorAuthenticationOption(state, action) {
            state.selectedTwoFactorAuthenticationOption = action.payload;
        },

        setTwoFactorAuthenticationToken(state, action) {
            state.twoFactorAuthenticationToken = action.payload;
        },

        setUserEnrollmentTokenMessage(state, action) {
            state.userEnrollmentTokenMessage = action.payload;
        },

        setNextStep(state, action) {
            state.nextStep = action.payload;
        },

        setHealthcareOrganization(state, action) {
            state.healthcareOrganization = action.payload;
        },

        setClientOrganization(state, action) {
            state.clientOrganization = action.payload;
        },

        initializeForgotPassword(state, action) {
            state.forgotPassword = {
                loginId: state.loginId,
                touched: {
                    loginId: !!state.loginId,
                },
            };
        },

        initializeChangePassword(state, action) {
            state.changePassword = {
                password: undefined,
                password2: undefined,
            };
        },
    },
});

export const {
    setLoginId,
    setUser,
    clearUser,
    setPassword,
    setToken,
    setExpiryTimeoutToken,
    clearExpiryTimeoutToken,
    setGenericLoginMessage,
    setFriendlyKickoutMessage,
    clearFriendlyKickoutMessage,
    clearToken,
    setReturnUrl,
    clearReturnUrl,
    setRememberMe,
    setLastLoginId,
    setLoggedOutInactive,
    setRouteLogin,
    setAuthenticationFailure,
    setForgotPasswordMessage,
    setForgotPasswordRequestFailed,
    setChangePasswordMessage,
    setTwoFactorMessage,
    hideChangePassword,
    setTwoFactorAuthenticationOptions,
    setShouldValidateTwoFactorAuthentication,
    setSelectedTwoFactorAuthenticationOption,
    setTwoFactorAuthenticationToken,
    setUserEnrollmentTokenMessage,
    setNextStep,
    setHealthcareOrganization,
    setClientOrganization,
    initializeForgotPassword,
    initializeChangePassword,
} = slice.actions;
export default slice.reducer;

// Thunks (side effects)
export const routeLogin = () => async (dispatch, getState) => {
    try {
        let { loginId, returnUrl, lastLoginId } = getState().auth;
        if (loginId === '') throw new Error('You must specify a Login ID');

        if (lastLoginId) {
            if (lastLoginId !== loginId) {
                returnUrl = null;
                dispatch(clearReturnUrl());
            }
        }

        dispatch(setLastLoginId(loginId));

        // Take current returnUrl in  state, pass to routeLogin API, let it recompute that
        const routeLogin = await ssoService.routeLogin({ loginId, returnUrl });

        // Now that routeLogin determined our route strategy and new returnUrl take the corresponding path
        if (routeLogin.isLocalLogin) {
            dispatch(setRouteLogin(routeLogin));
        } else {
            const { provider } = routeLogin;
            dispatch(externalLogin(provider, routeLogin.returnUrl));
        }
    } catch (err) {
        dispatch(setAuthenticationFailure(err.message));
    }
};

export const localLogin = () => async (dispatch, getState) => {
    try {
        const { loginId, password, returnUrl } = getState().auth;
        const localLoginResult = await ssoService.localLogin({
            loginId,
            password,
            returnUrl,
        });

        dispatch(setLoggedOutInactive(false));
        handleLoginStepResult(dispatch, getState, localLoginResult);
    } catch (err) {
        dispatch(setAuthenticationFailure(err.message));
    }
};

export const externalLogin = (provider, returnUrl) => async (dispatch, getState) => {
    try {
        const form = document.createElement('FORM');
        form.method = 'POST';
        form.action = `${API_URL}/api/auth/external/challenge`;
        const providerInput = document.createElement('INPUT');
        providerInput.type = 'hidden';
        providerInput.name = 'provider';
        providerInput.value = provider;
        form.appendChild(providerInput);
        const returnUrlInput = document.createElement('INPUT');
        returnUrlInput.type = 'hidden';
        returnUrlInput.name = 'returnUrl';
        returnUrlInput.value = returnUrl;
        form.appendChild(returnUrlInput);
        document.body.appendChild(form);
        form.submit();
    } catch (err) {
        dispatch(setAuthenticationFailure(err.message));
    }
};

export const finalizeLogout = () => async (dispatch, getState) => {
    Cookies.remove(portalKey, { domain });
    dispatch(clearUser());

    try {
        // Logout of API
        await fetch(`${API_URL}/api/auth/logout`, {
            method: 'GET',
            credentials: 'include',
        });
    } catch (err) {
        console.log('Failed to logout of API, ' + err.message);
    }

    Cookies.remove(tokenKey, { domain });
    dispatch(clearToken());

    try {
        // Logout of MVC
        await fetch(`${LEGACY_APP_URL}/Login/logout`, {
            method: 'GET',
            credentials: 'include',
        });
    } catch (err) {
        console.log('Failed to logout of Legacy, ' + err.message);
    }
};

export const sendForgotPasswordEmail = () => async (dispatch, getState) => {
    const { forgotPassword } = getState().auth;
    const { loginId } = forgotPassword;
    const resetPasswordResult = await ssoService.forgotPassword({ loginId });
    if (resetPasswordResult.wasSuccessful) {
        dispatch(
            setForgotPasswordMessage(
                'If a matching account was found, then you will receive an email with password reset instructions.',
            ),
        );
    } else {
        dispatch(setForgotPasswordRequestFailed(true));
    }
};

export const validateChangePasswordToken = () => async (dispatch, getState) => {
    const validateResult = await ssoService.validateChangePasswordToken({ changePasswordId });
    dispatch(hideChangePassword(!validateResult.wasSuccessful));
    if (!validateResult.wasSuccessful) {
        dispatch(setChangePasswordMessage(validateResult.validationErrors.general));
    }
};

export const changePassword = () => async (dispatch, getState) => {
    const { changePassword: formData } = getState().auth;
    const { password, password2 } = formData;
    if (password !== password2) {
        return dispatch(setChangePasswordMessage('Your passwords do not match'));
    }

    const changePasswordResult = await ssoService.changePassword({ changePasswordId, password });
    if (!changePasswordResult.wasSuccessful) {
        dispatch(setChangePasswordMessage(changePasswordResult.validationErrors.general));
    } else {
        handleLoginStepResult(dispatch, getState, changePasswordResult);
    }
};

export const getTwoFactorAuthenticationOptions = () => async (dispatch, getState) => {
    const { notificationMethodId } = getState().auth;
    const result = await ssoService.getTwoFactorAuthenticationOptions({ notificationMethodId });
    if (!result.wasSuccessful) {
        dispatch(setAuthenticationFailure('There was an error getting notification options.'));
    } else {
        dispatch(setTwoFactorAuthenticationOptions(result.availableNotificationOptions));
    }
};

export const sendTwoFactorAuthentication = () => async (dispatch, getState) => {
    const { selectedTwoFactorAuthenticationOption } = getState().auth;
    const result = await ssoService.sendTwoFactorAuthentication({
        selectedTwoFactorAuthenticationOption,
    });
    if (!result.wasSuccessful) {
        dispatch(
            setAuthenticationFailure('Failed to send an authorization request. Please try again.'),
        );
    } else {
        dispatch(setShouldValidateTwoFactorAuthentication(true));
    }
};

export const validateTwoFactorAuthentication = () => async (dispatch, getState) => {
    const { twoFactorAuthenticationToken } = getState().auth;
    const result = await ssoService.validateTwoFactorAuthentication({
        twoFactorAuthenticationToken,
    });
    if (!result.wasSuccessful) {
        dispatch(setAuthenticationFailure('The token you entered is invalid.'));
    } else {
        handleLoginStepResult(dispatch, getState, result);
    }
};

export const handleLoginStepResult = (dispatch, getState, result) => {
    if (result.wasSuccessful) {
        if (result.user) {
            dispatch(setUser(result.user));
        }

        if (result.healthcareOrganization) {
            dispatch(setHealthcareOrganization(result.healthcareOrganization));
        }

        if (result.clientOrganization) {
            dispatch(setClientOrganization(result.clientOrganization));
        }

        if (result.token) {
            dispatch(setToken(result.token));
            dispatch(initializeChangePassword());
        }
        if (result.nextStep) {
            // Redirect to View
            dispatch(setNextStep(result.nextStep));
        } else {
            const { returnUrl } = getState().auth;
            // returnUrl is from the URL query string, we want to use that over the default url for the userType from the API
            const finalReturnUrl = returnUrl || result.returnUrl;

            if (finalReturnUrl) {
                console.log(`Handling login, redirecting to ${finalReturnUrl}`);
                window.location = finalReturnUrl;
            } else {
                //TODO: Change in the future once we need to direct to different portals
                // - Maybe check the Cookie.Portals value and direct based on that?
                console.log(`Handling login, redirecting to Provider Portal`);
                window.location = PROVIDER_PORTAL_URL;
            }
        }
    } else {
        dispatch(
            setAuthenticationFailure({
                authenticationFailure: result.validationErrors.general,
                loginFailureReason: result.loginFailureReason,
            }),
        );
    }
};
