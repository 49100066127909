import { createSlice } from '@reduxjs/toolkit';
import { handleLoginStepResult } from './auth';

const slice = createSlice({
    name: 'userEnrollmentWizard',
    initialState: {
        userEnrollmentToken: null,
        finalResult: null,
    },
    reducers: {
        setUserEnrollmentToken(state, action) {
            state.userEnrollmentToken = action.payload;
        },
        setFinalResult(state, action) {
            state.finalResult = action.payload;
        },
    },
});

export const { setUserEnrollmentToken, setFinalResult } = slice.actions;

export default slice.reducer;

// Thunks (side-effects)

export const completeUserEnrollment = () => async (dispatch, getState) => {
    const { finalResult } = getState().userEnrollmentWizard;
    handleLoginStepResult(dispatch, getState, finalResult);
};
