/**
 * Function which will comma-delimit a list of strings and apply the specified conjunction
 * between the penultimate and final string in the list.  If the list contains 2 items, the
 * delimiter will be ignored and only the conjunction will be used.  If the list contains 3
 * or more items, both the delimiter and the conjunction will be used.  I'm old-school like
 * that.  Do not specify spaces when specifying conjunction and/or delimiter (e.g. specify
 * "," rather than ", " for the delimiter and "and" rather than " and "); spaces will be added
 * automatically.  If delimiter is not specified, ", " will be used.  There is no default for
 * conjunction.
 */
export function delimitWithConjunction(list, conjunction, delimiter) {
    delimiter = !delimiter ? ', ' : delimiter + ' ';
    conjunction = !conjunction ? '' : ' ' + conjunction + ' ';

    if (!list) return null;

    list = list.filter((x) => x && x.length > 0);

    if (list.length === 0) return '';
    else if (list.length === 1) return list[0] || '';
    else if (list.length === 2) return (list[0] || '') + conjunction + (list[1] || '');
    else {
        let result = list.join(delimiter);
        let insertionIndex = result.length - (list[list.length - 1] || '').length;
        if (conjunction.length > 0) conjunction = conjunction.substring(1); // strip the leading space since it's already accounted for by the delimiter
        result =
            result.substring(0, insertionIndex) + conjunction + result.substring(insertionIndex);
        return result;
    }
}

export function startsWithVowel(text) {
    return (
        text && text.length > 0 && ['a', 'e', 'i', 'o', 'u'].includes(text.charAt(0).toLowerCase())
    );
}

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const parseIfJson = (str) => {
    if (isJson(str)) {
        return JSON.parse(str);
    }
    return str;
};

export const safeParseJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        console.error('Error parsing JSON:', e);
        return str;
    }
};

export const isTruthy = (str) =>
    ['true', 't', 'yes', 'y', '1'].some((x) => x === str?.toLowerCase());

export const isLowerCase = (str) => {
    if (!str) return false;
    return str === str.toLowerCase() && str !== str.toUpperCase();
};
export const isUpperCase = (str) => {
    if (!str) return false;
    return str === str.toUpperCase() && str !== str.toLowerCase();
};

export const splitCamelCase = (str) => {
    if (!str) return [];
    return str.split(/(?=[A-Z])/);
};
