import * as React from 'react';
import PropTypes from 'prop-types';

export const LinkBase = ({ className, dataTestId, onClick, children, host, href, target }) => {
    const url = host && href ? host + href : href;

    const handleOnClick = (e) => {
        if ((url || '' === '') && onClick) {
            e.preventDefault();
        }
        onClick && onClick(e);
    };

    return (
        <a
            className={className}
            data-test-id={dataTestId}
            onClick={handleOnClick}
            href={url}
            target={target}
        >
            {children}
        </a>
    );
};

LinkBase.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    host: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
};
