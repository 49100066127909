import React from 'react';
import PropTypes from 'prop-types';
import { ActionLink } from '../../nav/ActionLink';
import { ExceptionDetails } from './ExceptionDetails';
import * as Layout from './ErrorDisplay.layout';
import './ErrorDisplay.scss';

export const ErrorDisplay = ({ error, errorCode, showDetails }) => {
    return (
        <Layout.Exception alert>
            <Layout.ExceptionContainer>
                <Layout.ExceptionHeader>
                    {/*<UrgentIcon />*/}
                    Care Continuity has encountered an error.
                </Layout.ExceptionHeader>
                <Layout.ExceptionMessage>
                    This error has been logged and our team will be looking into it as soon as
                    possible. We are sorry for any inconvenience this may have caused.
                </Layout.ExceptionMessage>
                <Layout.ExceptionMessage>
                    We are providing you with an error code for future reference:
                </Layout.ExceptionMessage>
                <Layout.ExceptionCode>{errorCode}</Layout.ExceptionCode>
                <Layout.ExceptionMessage>
                    <ActionLink href={process.env.PUBLIC_URL} className="exception__link">
                        Click here
                    </ActionLink>{' '}
                    to go to the home page.
                </Layout.ExceptionMessage>
                {showDetails && <ExceptionDetails error={error} />}
            </Layout.ExceptionContainer>
        </Layout.Exception>
    );
};

ErrorDisplay.propTypes = {
    error: PropTypes.any,
    errorCode: PropTypes.string,
    showDetails: PropTypes.bool,
};
