import { createSlice } from '@reduxjs/toolkit';
import commonService from '../../services/commonServiceInstance';
import { handleApiCall } from '../../helpers/handleApiCall';
import { MessagePriority } from '../../enums';
import { setNotification } from '../../redux/slices/notifications';
import isEqual from 'lodash/isEqual';
import isBefore from 'date-fns/isBefore';

const initialState = {
    conversations: null,
    conversationsPage: 1,
    conversationKey: null,
    isLoadingMessages: false,
    isLoadingMetadata: false,
    includeArchived: false,
    loadedConversationsPages: [],
    loadedMessagesPages: [],
    messages: null,
    messagesPage: 1,
    metadata: null,
    numConversationsPages: 1,
    numMessagesPages: 1,
    onBehalfOfUserId: null,
    patientId: null,
    hasImpersonatableUsers: false,
    hasLoadedInitialConversations: false,
    impersonatableUsers: null,
    messageToSend: {
        patientId: null,
        recipients: [],
        priority: null,
        message: '',
    },
    quickReplyToSend: {
        isUrgent: false,
        message: '',
    },
    unreadMessageCount: 0,
    queryStringToReduxComplete: false,
    hasInitiatedFirstConversationLoad: false,
};

const slice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setNewMessageRecipients(state, action) {
            state.messageToSend.recipients = action.payload;
        },
        setOnBehalfOfUserId(state, action) {
            state.onBehalfOfUserId = action.payload;
        },
        setOnBehalfOfUser(state, action) {
            //expects input of userId
            state.onBehalfOfUser = state.impersonatableUsers?.find(
                ({ userId }) => userId == parseInt(action.payload),
            );
        },
        setImpersonatableUsers(state, action) {
            state.impersonatableUsers = action.payload;
        },
        setHasImpersonatableUsers(state, action) {
            state.hasImpersonatableUsers = action.payload;
        },
        setConversationKey(state, action) {
            state.conversationKey = action.payload;
        },
        setConversations(state, action) {
            state.conversations = action.payload;
        },
        setConversationMessageCount(state, action) {
            state.unreadMessageCount = action.payload;
        },
        setConversationArchived(state, action) {
            state.metadata.isArchived = action.payload;
        },
        setMessages(state, action) {
            state.messages = action.payload;
        },
        setMetadata(state, action) {
            state.metadata = action.payload;
            state.conversationKey = action.payload?.conversationKey;
        },
        setIsLoadingMessages(state, action) {
            state.isLoadingMessages = action.payload;
        },
        setIsLoadingMetadata(state, action) {
            state.isLoadingMetadata = action.payload;
        },
        succeedLoadingConversations(state, action) {
            const { items, page, numPages, loadedConversationsPages } = action.payload;
            state.conversationsPage = page;
            state.numConversationsPages = numPages;
            state.loadedConversationsPages = loadedConversationsPages;
            state.conversations = items;
        },
        succeedLoadingMetadata(state, action) {
            state.isLoadingMetadata = false;
            state.metadata = action.payload;
            state.conversationKey = action.payload?.conversationKey;
        },
        succeedLoadingMessages(state, action) {
            const { items, page, numPages, loadedMessagesPages } = action.payload;
            state.messagesPage = page;
            state.numMessagesPages = numPages;
            state.loadedMessagesPages = loadedMessagesPages;
            state.messages = items;
            state.isLoadingMessages = false;
        },
        failLoadingMetadata(state) {
            state.isLoadingMetadata = false;
        },
        failLoadingMessages(state) {
            state.isLoadingMessages = false;
        },
        setHasInitiatedFirstConversationLoad(state) {
            state.hasInitiatedFirstConversationLoad = true;
        },
        reset(state) {
            state.onBehalfOfUserId = null;
            state.patientId = null;
            state.conversationKey = null;
            state.conversations = null;
            state.messages = null;
            state.metadata = null;
            state.isLoadingMessages = false;
            state.isLoadingMetadata = false;
            state.includeArchived = false;
            state.loadedConversationsPages = [];
            state.loadedMessagesPages = [];
            state.numConversationsPages = 1;
            state.numMessagesPages = 1;
            state.hasLoadedInitialConversations = false;
            state.messageToSend = {
                patientId: null,
                recipients: [],
                priority: null,
                message: '',
            };
            state.quickReplyToSend = {
                isUrgent: false,
                message: '',
            };
            state.queryStringToReduxComplete = false;
            state.hasInitiatedFirstConversationLoad = false;
        },
    },
});

export const {
    setNewMessageRecipients,
    setOnBehalfOfUserId,
    setOnBehalfOfUser,
    setImpersonatableUsers,
    setHasImpersonatableUsers,
    setHasInitiatedFirstConversationLoad,
    setConversationKey,
    setConversations,
    setConversationMessageCount,
    setConversationArchived,
    setMessages,
    setMetadata,
    setIsLoadingMetadata,
    setIsLoadingMessages,
    succeedLoadingConversations,
    succeedLoadingMetadata,
    succeedLoadingMessages,
    failLoadingMetadata,
    failLoadingMessages,
    reset,
} = slice.actions;

export const messages = { reducer: slice.reducer, initialState };
export default messages;

// Thunks
export const loadConversations =
    ({
        page,
        conversationKey,
        appendTo,
        onBehalfOfUserId,
        isInitialLoad,
        shouldLoadConversation = true,
        patientId,
    }) =>
    async (dispatch, getState) => {
        const { conversations, loadedConversationsPages, includeArchived } = getState().messages;
        const pageSize = 20;

        if (isInitialLoad) dispatch(setHasInitiatedFirstConversationLoad());

        let pageContainingConversation;
        if (conversationKey) {
            const result = await handleApiCall(
                async () =>
                    await commonService.getConversationPage({
                        conversationKey,
                        pageSize,
                        onBehalfOfUserId,
                    }),
                {
                    dispatch,
                    shouldShowErrorDialog: false,
                },
            );

            pageContainingConversation = result.wasSuccessful ? result.page : 1;
        }

        const result = await handleApiCall(
            async () =>
                await commonService.getUserConversations({
                    onBehalfOfUserId,
                    page: pageContainingConversation || page,
                    pageSize,
                    patientId,
                    includeArchived,
                }),
            {
                dispatch,
                errorMessage: 'Error loading conversations.',
            },
        );

        if (result.wasSuccessful) {
            await dispatch(
                succeedLoadingConversations({
                    ...result.data,
                    items: updateItems(conversations, result.data.items, appendTo),
                    loadedConversationsPages: updateLoadedPages(loadedConversationsPages, page),
                    isInitialLoad,
                }),
            );

            if (
                (isInitialLoad && !conversationKey) ||
                !result.data.items?.length ||
                !shouldLoadConversation
            )
                return result;

            if (conversationKey) {
                //If theres a conversation key, load it
                dispatch(loadConversation({ conversationKey, onBehalfOfUserId }));
            } else {
                const firstConversationKey = result.data.items[0]?.conversationKey;
                //If not load the first conversation
                dispatch(
                    loadConversation({ conversationKey: firstConversationKey, onBehalfOfUserId }),
                );
            }
        }

        return result;
    };

// convenience thunk for loading meta and messages which are usually loaded together
export const loadConversation =
    ({ appendTo, conversationKey, isInitialLoad, page, userMessageId, onBehalfOfUserId }) =>
    async (dispatch) => {
        dispatch(loadMetadata(conversationKey, onBehalfOfUserId));
        dispatch(
            loadMessages({
                appendTo,
                conversationKey,
                isInitialLoad,
                page,
                userMessageId,
                onBehalfOfUserId,
            }),
        );
    };

export const loadMetadata = (conversationKey, onBehalfOfUserId) => async (dispatch, getState) => {
    dispatch(setIsLoadingMetadata(true));

    const result = await handleApiCall(
        async () =>
            await commonService.getSingleUserConversation({
                conversationKey: conversationKey,
                onBehalfOfUserId: onBehalfOfUserId,
            }),
        { dispatch },
    );

    if (result.wasSuccessful && result.conversation) {
        dispatch(loadUnreadMessageCount());
        dispatch(succeedLoadingMetadata(result.conversation));
        return;
    } else {
        dispatch(displayAccessDeniedToast());
    }

    return dispatch(failLoadingMetadata());
};

export const loadMessages =
    ({ appendTo, conversationKey, isInitialLoad, page, userMessageId, onBehalfOfUserId }) =>
    async (dispatch, getState) => {
        const { loadedMessagesPages, messages } = getState().messages;
        const pageSize = 20;

        if (!appendTo) {
            await dispatch(setIsLoadingMessages(true));
        }

        let pageContainingMessage;
        if (userMessageId) {
            const result = await handleApiCall(
                async () =>
                    await commonService.getMessagePage({
                        userMessageId,
                        pageSize,
                        onBehalfOfUserId,
                    }),
                { dispatch },
            );

            pageContainingMessage = result.wasSuccessful ? result.page : 1;
        }

        const result = await handleApiCall(
            async () =>
                await commonService.getUserConversationMessages({
                    conversationKey,
                    onBehalfOfUserId,
                    page: pageContainingMessage || page,
                    pageSize,
                }),
            { dispatch },
        );

        if (result.wasSuccessful) {
            const { data } = result;
            const sortedItems = [...data.items].sort((a, b) =>
                isBefore(new Date(a.dateSent), new Date(b.dateSent)) ? -1 : 1,
            );
            const newLoadedPages = isInitialLoad
                ? [data.page]
                : updateLoadedPages(loadedMessagesPages, data.page);
            return dispatch(
                succeedLoadingMessages({
                    ...result.data,
                    items: updateItems(messages, sortedItems, appendTo),
                    loadedMessagesPages: newLoadedPages,
                }),
            );
        }

        return dispatch(failLoadingMessages());
    };

export const sendMessage = () => async (dispatch, getState) => {
    const onBehalfOfUserId = getState().messages.onBehalfOfUserId;
    const { patientId, recipients, message, priority } = getState().messages.messageToSend;
    const recipientIds = recipients.map((r) => r.userId);

    const result = await handleApiCall(
        async () =>
            await commonService.sendMessage({
                message,
                recipientUserIds: recipientIds,
                priority: priority ? MessagePriority.Urgent : MessagePriority.Normal,
                patientId,
                onBehalfOfUserId,
            }),
        {
            dispatch,
            successMessage: 'Message sent successfully',
            mapFormErrors: true,
            slice: 'messages',
            prefix: 'messageToSend',
        },
    );

    if (result.wasSuccessful && result.result) {
        await dispatch(loadUnreadMessageCount());

        const conversations = getState().messages.conversations;
        if (conversations) {
            const existingConversations = conversations.filter(
                (c) => c.conversationKey !== result.result.conversationKey,
            );
            await dispatch(setConversations([result.result, ...existingConversations]));
        }
    }

    return result;
};

export const sendQuickReply = () => async (dispatch, getState) => {
    const { messages, quickReplyToSend, onBehalfOfUserId, conversationKey, patientId } =
        getState().messages;
    const { message, isUrgent } = quickReplyToSend;

    const result = await commonService.sendQuickReply({
        conversationKey,
        message,
        priority: isUrgent ? MessagePriority.Urgent : MessagePriority.Normal,
        onBehalfOfUserId,
    });

    const newMessages = [...messages, { ...result.result, isRead: true }];

    dispatch(loadUnreadMessageCount());

    return dispatch(setMessages(newMessages));
};

export const archiveConversation = (archiveConversation) => async (dispatch, getState) => {
    const { conversationKey, onBehalfOfUserId, metaData } = getState().messages;
    const result = await handleApiCall(
        async () =>
            commonService.archiveUserConversation({
                conversationKey,
                archiveConversation,
                onBehalfOfUserId,
            }),
        {
            dispatch,
            successMessage: `Conversation ${
                archiveConversation ? 'archived' : 'unarchived'
            } successfully`,
            shouldShowErrorToast: true,
            slice: 'messages',
        },
    );

    if (result.wasSuccessful) {
        dispatch(refreshConversationList(!!archiveConversation));
        // if unarchiving make sure to correctly toggle the redux value for checkbox toggle to be in sync
        if (!archiveConversation) dispatch(setConversationArchived(false));
    }
};

export const refreshConversationList = (isArchiving) => async (dispatch, getState) => {
    const { onBehalfOfUserId, patientId, conversations, conversationKey } = getState().messages;

    let nextConversationKey, nextConversationIndex;
    if (isArchiving) {
        nextConversationIndex = conversations.findIndex(
            (c) => c.conversationKey === conversationKey,
        );
        nextConversationKey = conversations[nextConversationIndex + 1]?.conversationKey;
    }

    dispatch(
        loadConversations({
            onBehalfOfUserId,
            patientId,
            shouldLoadConversation: isArchiving,
            conversationKey: nextConversationKey ?? null,
        }),
    );
};

export const loadUnreadMessageCount = () => async (dispatch, getState) => {
    const { impersonatableUsers } = getState().messages;
    const { user } = getState().auth;
    const result = await commonService.getImpersonatableUsers({
        includeUnreadMessageCounts: true,
    });

    if (result.wasSuccessful) {
        const count = result.users.reduce(
            (total, { unreadMessageCount }) => total + unreadMessageCount,
            0,
        );

        if (!isEqual(impersonatableUsers, result.users)) {
            dispatch(setImpersonatableUsers(result.users));
            dispatch(
                setHasImpersonatableUsers(
                    result.users && result.users.some(({ userId }) => userId !== user?.userId),
                ),
            );
        }

        return dispatch(setConversationMessageCount(count));
    }
};

export const displayAccessDeniedToast = () => async (dispatch) => {
    dispatch(
        setNotification({
            message: "We're sorry, this conversation doesn't exist, or you do not have access.",
            options: { appearance: 'warning', autoDismiss: false },
        }),
    );
};

export const displayMessageSuccessToast = () => async (dispatch) => {
    dispatch(
        setNotification({
            message: 'Message successfully sent',
            options: { appearance: 'success', autoDismiss: true },
        }),
    );
};

// helpers
const updateLoadedPages = (loadedPages, page) => {
    if (!loadedPages) return [page];
    if (loadedPages.includes(page)) return loadedPages;
    const newArray = [...loadedPages, page];
    newArray.sort();
    return newArray;
};

const updateItems = (items, newItems, appendTo) => {
    if (!items) return newItems;
    if (appendTo === 'bottom') {
        return [...items, ...newItems];
    } else if (appendTo === 'top') {
        return [...newItems, ...items];
    } else {
        return newItems;
    }
};
